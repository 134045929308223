import React, { useMemo, useState, useEffect, useContext } from "react";
import { LugBulkContext } from "../Context/LugBulkContext";

import axios from 'axios';
import DataTable from 'react-data-table-component';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import PartImage from '../Parts/PartImage';
import CustomLoader from '../Widgets/Loading';
import CountSheetPDF from './CountSheetPDF';

const convertArrayOfObjectsToCSV = (data, header) => {
  let result;
  
  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  //const keys = Object.keys(data[0]);
  result = '';
  result += header.map(h => quoter(h.part_name)).join(columnDelimiter);
  //result += keys.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach(item => {
    let ctr = 0;
    header.forEach(h => {
      if (ctr > 0) result += columnDelimiter;
      result += '"' + item[h.selector] + '"';
      ctr++;
    });
    result += lineDelimiter;
  });
  return result;
}

const quoter = (str) => {
  return '"' + str + '"';
}

const downloadCSV = (data, headers) => {
  
  const link = document.createElement('a');
  let csv = convertArrayOfObjectsToCSV(data, headers);
  if (csv == null) return;
  const dtestr = new Date().getTime().toString();
  const filename = 'countsheet_'+dtestr+'.csv';

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute('href', encodeURI(csv));
  link.setAttribute('download', filename);
  link.click();
}

const downloadPDF = (data, headers) => {
  return <CountSheetPDF data={data} columns={headers} />;
}

const Export = ({ onExport }) => (
  <Button size="sm" onClick={e => onExport(e.target.value)}>Export CSV</Button>
);
const ExportPDF = ({ onExport }) => (
  <Button size="sm" onClick={e => onExport(e.target.value)}>Export PDF</Button>
);

function CountSheet() {
  const { context } = useContext(LugBulkContext);
	const user = context.User;
	const token = context.User.token;
	const lug = context.Lug;
  const serverURL = process.env.REACT_APP_SERVER_URL;
  
  const [ parts, setParts ] = useState([]);
  const [ orders, setOrders ] = useState([]);
  const [ columns, setColumns ] = useState([]);
  const [ data, setData ] = useState([]);

  const [ pending, setPending ] = useState(true);

  useEffect(() => {
		(async () => {
			await axios.get(serverURL+'/parts/lug/nominated', {headers: {'x-access-token': token }}).then(resp => {
        if (resp && resp.data) setParts(resp.data);
      });
      await axios.get(serverURL+'/orders/countsheet', {headers: {'x-access-token': token }}).then(resp => {
        if (resp && resp.data) setOrders(resp.data);
      }).then(setPending(false));
		})();
  }, []);

  useEffect(() => {
    let _columns = [];
    _columns.push({
      name: "User",
      part_name: "User",
      selector: "username",
    });
    parts.map(p => {
      p.part.title = UCWords(p.part.title);
      _columns.push({
        name: <ColumnHeader part={p.part} />,
        part_name: p.part.title + ' ' + p.part.color.title,
        selector: p.part.id.toString(),
        width: '150px',
      });
    });
    setColumns(_columns)
  }, [parts]);

  const ColumnHeader = ({part}) => {
    return (<>
      {part.title}<br/>{part.color.title}<br/>
      <PartImage part_id={part.id} width={40} />
    </>);
  };

  const UCWords = (str) => {
    return str.toLowerCase().replace(/^[\u00C0-\u1FFF\u2C00-\uD7FF\w]|\s[\u00C0-\u1FFF\u2C00-\uD7FF\w]/g, function(letter) {
      return letter.toUpperCase();
    });
  }

  const actionsMemo = React.useMemo(() => <><Export onExport={() => downloadCSV(orders, columns)} /><ExportPDF onExport={() => downloadPDF(orders, columns)} /></>, [orders, parts]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="striped-tabled-with-hover">
              <Card.Header>Count Sheet</Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <DataTable 
                  columns={columns} 
                  data={orders} 
                  actions={actionsMemo}
                  progressPending={pending}
                  progressComponent={<CustomLoader />}
                  pagination
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      </>
    );
}

export default CountSheet;
import React, { useState, useEffect, useContext, useMemo } from "react";
import { LugBulkContext } from "../Context/LugBulkContext";

import { useParams, Link } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import PartImage from '../Parts/PartImage';
import CustomLoader from '../Widgets/Loading';
import OrderTotalBox from "../Orders/OrderTotalBox";

function UserOrderDetail({data, readyState, viewingUser}) {
	const { context } = useContext(LugBulkContext);
	const user = context.User;
	const token = context.User.token;
	const lug = context.Lug;
	const { year } = useParams();

  const [ order, setOrder ] = useState([]);
	const [ pending, setPending ] = useState(true);
  const [ viewing, setViewing ] = useState(user.Username);

	const serverURL = process.env.REACT_APP_SERVER_URL;
  const rate = process.env.REACT_APP_CONVERSION_RATE;

  let sumTotalCurrency = 0;

  useEffect(() => {
		(async () => {
      if (!data) {
        await axios.get(serverURL+'/orders/my-orders/' + year, { headers: {'x-access-token': token }}).then(resp => {
          setOrder(resp.data);	
          setPending(false);
        });
      } else {
        setOrder(data);
        setViewing(viewingUser);
      }
		})();
	}, [data]);

  useEffect(() => {
    setPending(readyState);
    if (!readyState) setViewing(viewingUser);
  }, [readyState]);

  const getLineTotal = (r) => {
    if (r.quantity === null || r.quantity === 'undefined') return 0;
		return (r.quantity * r.price).toFixed(2);
	}

  const getConvertedTotal = (r) => {
    let _total = (r.quantity === null || r.quantity === 'undefined')? 0:  parseInt(r.quantity) * parseFloat(r.price);
		return (_total * parseFloat(rate)).toFixed(2);
	}

	if (order && order.length > 0) {
		for (let i = 0; i < order.length; i++) {
			sumTotalCurrency += parseFloat((parseInt(order[i].quantity) * parseFloat(order[i].price) * parseFloat(rate)));
		}
	}

  const ImageCell = (row) => {
		return (<>
			<PartImage part_id={row.row.part_id} width={40} />
		</>);
	}

  const WrapCell = (val) => {
    return (<>
      <span>{val}</span>
    </>);
  }


  const columns = useMemo(
		() => [
      {
        name: "Image", 
        cell: row => <ImageCell row={row} />
      },
      {
        name: "Part Id",
        selector: "part_id",
        sortable: true,
        cell: row => row.part_id,
      },
      {
        name: "Title",
        selector: "part.title",
        sortable: true,
        cell: row => row.part.title,
      },
      {
        name: "Lego Colour",
        selector: "part.color.title",
        sortable: true,
        cell: row => row.part.color.title,
      },
      {
        name: "BL Colour",
        selector: "part.blcolor.title",
        sortable: true,
        cell: row => row.part.blcolor.title,
      },
      {
        name: "Price",
        selector: "part.price",
        sortable: true,
        cell: row => row.part.price,
      },
      {
        name: "Currency",
        selector: "part.currency",
        sortable: true,
        cell: row => row.part.currency,
      },
      {
        name: "Quantity",
        selector: "quantity",
        sortable: true,
        cell: row => row.quantity,
      },
      {
        name: "Total",
        sortable: true,
        cell: row => getLineTotal(row),
      },
      {
        name: "Total AUD",
        sortable: true,
        cell: row => getConvertedTotal(row),
        footer: sumTotalCurrency
      },
    ], []
  );

  const MyHeading = () => {
    return 'My Order ' + year; 
  }

  const UserHeading = () => {
    return 'Viewing ' + viewing;
  }
  
  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <Card className="striped-tabled-with-hover">
              <Card.Header>{viewing && viewing !== ''? <UserHeading />: <MyHeading />}</Card.Header>
              <Card.Body>
                <DataTable 
                  columns={columns} 
                  data={order} 
                  progressPending={pending}
                  progressComponent={<CustomLoader />}
                  striped
                  noHeader
                />
                <OrderTotalBox orderTotal={sumTotalCurrency} />
              </Card.Body>
      			</Card>
      		</Col>
      	</Row>
      </Container>
    </>
  );
}

export default UserOrderDetail;
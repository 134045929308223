import React, { useState, useEffect, useContext, useMemo } from "react";
import { LugBulkContext } from "../Context/LugBulkContext";
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications'
import { Container, Row, Col, Card, Nav, Alert, Form, Toast, ToastContainer } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import PartImage from '../Parts/PartImage';
import Moment from 'react-moment';
import CustomLoader from '../Widgets/Loading';
import ShoppingImage from '../../assets/img/shopping-image.png';


const TableInput = ({value: initialValue, index, price, handleInputChange, }) => {
  const [value, setValue] = useState(initialValue);
  const [min, setMin] = useState(1);
  const [step, setStep] = useState(1);
  const [invalid, setInvalid] = useState(false);
  
  const { addToast } = useToasts();

  const onChange = e => {
    setInvalid(false);
    setValue(e.target.value)
  }

  const onBlur = (e) => {
    if (value && value !== ''){
      if (value < min) {
        addToast('You need to order a minimum of ' + min + ' pieces for this part', {
          appearance: 'error', 
          autoDismiss: true,
          autoDismissTimeout: 15000
        });
        setInvalid(true);
        return;
      } else {
        let overMin = value - min;
        if (overMin % step !== 0) {
          addToast('You need to order this part in multiples of ' + step + ' after the initial ' + min + ' pieces', {
            appearance: 'error', 
            autoDismiss: true,
            autoDismissTimeout: 15000
          });
          setInvalid(true);
          return; 
        }
      }
    }
    handleInputChange(index, value)
  }

  useEffect(() => {
    setValue(initialValue);
    setMin((price > 1.35)? 1: 50);
    setStep((price > 1.35)? 1: 25);
  }, [initialValue, price])

  return <Form.Control value={value} onChange={onChange} onBlur={onBlur} min={min} step={step} isInvalid={invalid} autoFocus={invalid}/>
}


function Ordering() {
  const { context } = useContext(LugBulkContext);
	const user = context.User;
	const token = context.User.token;
	const lug = context.Lug;
  const serverURL = process.env.REACT_APP_SERVER_URL;
  
	const [ nominated, setNominated ] = useState([]);
  const [ order, setOrder ] = useState([]);
  const [ expired, setExpired ] = useState(false);
  const [ pending, setPending ] = useState(true);
  const [ saving, setSaving ] = useState(true);
	const { addToast } = useToasts();

  const [ showNotes, setShowNotes ] = useState(false);
  const toggleShowNotes = (e) => {
    e.preventDefault();
    setShowNotes(!showNotes);
  }

  const [ lefttospend, setLefttospend ] = useState(0);

  const isExpired = () => {
		if (!lug.order_end) return false;
		let _now = new Date();
		let _chkdate = new Date(lug.order_end); 
		setExpired((+_chkdate < +_now)? 'disabled' : false);
	};

  useEffect(() => {
		(async () => {
      setOrder([]);
      isExpired();
      let userOrder = [];
      const uo = await axios.get(serverURL+'/orders/user', {headers: {'x-access-token': token }});
			if (uo !== undefined && uo.data !== '') userOrder = uo.data;

			const pln = await axios.get(serverURL+'/parts/lug/nominated', {headers: {'x-access-token': token }});
			if (pln !== undefined && pln.data !== '') {
        pln.data.map(p => {
          if (!p.hasOwnProperty('quantity')) {
            Object.assign(p, {
              quantity: undefined, 
              linetotal: 0
            });
          }
          let idx = userOrder.findIndex(o => o.part_id === p.part_id);
          if (idx > -1) {
            p['quantity'] = userOrder[idx]['quantity'];
            p['linetotal'] = calculateLineTotal(userOrder[idx]['price'], userOrder[idx]['quantity']);
          }
        })
        setNominated(pln.data);
        setPending(false);
      }
		})();
  }, []);

  useEffect(() => {
    handleUpdateTotal();
  }, [nominated]);

  const handleUpdateTotal = () => {
    setSaving(false);
    const orderCap = lug.order_limit || 0;
    const spent = nominated.map(li => li.linetotal).reduce((sum, val) => sum + val, 0);
    const left = roundCalculation(orderCap - spent);
    if (left < 0) setSaving(true);
    setLefttospend(left);
    if (!showNotes) setShowNotes(true);
  }

  const TotalSpend = () => {
    return (<>
      <ToastContainer style={{position: 'fixed', zIndex: 5555, top: 60, right: 30}}>
        <Toast className="notification" show={showNotes} onClose={toggleShowNotes}>
          <Toast.Header closeButton={true}>
            <strong className="mr-auto">Order Spend</strong>
          </Toast.Header>
          <Toast.Body>
            <div className="cart-modal">
              <div><p>Left to spend:</p><p className="ginormous">{lefttospend} EUR</p></div>
              <img src={ShoppingImage} />
            </div>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>); 
  }

  const handleSave = async () => {
    if (expired === 'disabled') {
      addToast('Ordering has been finalised. Please contact your LUG admin.', {
        appearance: 'error', 
        autoDismiss: true,
        autoDismissTimeout: 15000
      });
      return;
    }
    let orderitems = [];
    nominated.map(p => {
      orderitems.push({
        part_id: p.part_id, 
        quantity: p.quantity,
        price: p.part.price,
        currency: p.part.currency,
        status: 'pending'
      })
    });
    try {
      const result = await axios.put(serverURL+'/orders', orderitems, { headers: {'x-access-token': token }});
      if (result.status === 200) {
        addToast('Order Created', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 10000
        });
      }
    } catch (err) {
      addToast('Error saving order.', {
        appearance: 'error', 
        autoDismiss: true,
        autoDismissTimeout: 15000
      });
    }
  }

  const handleInputChange = async (idx, value) => {
    setNominated(prev => 
      prev.map((row, index) => {
        if (row.part_id === idx) {
          return {
            ...prev[index],
            ['quantity']:  (!value)? undefined: value,
            ['linetotal']: calculateLineTotal(parseFloat(row.part.price), value),
          }
        }
        return row;
      })
    );
  };

  const calculateLineTotal = (price, qty) => {
    if (!qty) return 0;
    return roundCalculation((qty * price));
  }

  const roundCalculation = (val) => {
    return +(Math.round(val + "e+2")  + "e-2");
  }

  const columns = React.useMemo(
    () => [
        {
          name: "Image",
          width: '100px',
          cell: row => <PartImage part_id={row.part.id} width={40} />
        },
        {
          name: "Part Id",
          selector: "part_id",
          width: '100px',
          sortable: true,
        },
        {
          name: "Part Details",
          selector: "part.title",
          sortable: true,
        },
        {
          name: "Colour",
          selector: "part.color.title",
          sortable: true,
        },
        {
          name: "Bricklink Colour",
          selector: "part.blcolor.title",
          sortable: true,
        },
        {
          name: "Price (EUR)",
          selector: "part.price",
          width: '100px',
          sortable: true,
        },
        {
          name: "Quantity",
          cell: row => <TableInput key={"quantity-" + row.id} index={row.part_id} value={row.quantity} price={row.part.price} handleInputChange={handleInputChange}/>,
          width: "130px",
        },
        {
          name: "Total",
          width: "160px",
          right: true,
          cell: row => <Form.Control key={"total-" + row.id} type="number" name={"total-" + row.part_id} value={row.linetotal} disabled="disabled"/>
        }
      ],
      [nominated, setNominated],
  );

  return (
    <>
    <Container fluid>
    	<Row>
    		<Col md="12">
          {expired === 'disabled' && <Alert key={10} variant={'danger'}>Ordering has now closed.</Alert> }
          {lug.order_end && !expired && <Alert key={10} variant={'warning'}>Ordering closes on {<Moment format='Do MMMM YYYY' >{lug.order_end}</Moment>}.</Alert> }
          <Card className="striped-tabled-with-hover">
            <Card.Header>
              Parts available to Order {lug.year} [{nominated.length}]
              <Nav className="ml-auto" defaultActiveKey="/ordering">
                <Nav.Item>
                  <Nav.Link as={Link} to="/ordering" onClick={handleSave} disabled={saving}>Save Order</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
            <DataTable 
              columns={columns} 
              data={nominated} 
              progressPending={pending}
			        progressComponent={<CustomLoader />}
              striped
            />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    <TotalSpend />  
    </>
  );
}

export default Ordering;
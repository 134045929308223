import React, { useState, useEffect, useContext } from "react";
import { LugBulkContext } from "../Context/LugBulkContext";

import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications'
import axios from 'axios';

import LugInvoicing from "./LugInvoicing";
import FileUpload from "./FileUpload";


function LugSettings() {
  const { context } = useContext(LugBulkContext);
  const serverURL = process.env.REACT_APP_SERVER_URL;
  const token = context.User.token;
  const [ lug, setLug ] = useState({});
  const [ lugAdmins, setLugAdmins ] = useState('');
  const [ users, setUsers ] = useState([]);
  const { addToast } = useToasts();

  useEffect(() => {
		(async () => {
      const lugsData = await axios.get(serverURL+'/lugs', {headers: {'x-access-token': token }});
			if (lugsData !== undefined) setLug(lugsData.data);

      await axios.get(serverURL+'/users', {headers: {'x-access-token': token }}).then(resp => {
        if (resp && resp.hasOwnProperty('data')) setUsers(resp.data);
      });
		})();
	}, []);

  useEffect(() => {
    if (lug.admins && lug.admins !== ''){
      if (lug.admins.indexOf(',') > -1) { 
        setLugAdmins(lug.admins.split(',').map(Number)); 
      } else {
        setLugAdmins(lug.admins);
      }
    }
  }, [lug]);

  const handleInputChange = async (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setLug({...lug, 
      [name]: value
    });
  }

  const handleSave = async (e) => {
    e.preventDefault();
    const result = await axios.put(serverURL+'/lugs', lug, { headers: {'x-access-token': token }});
    if (result.status === 200) {
      addToast('LUG Updated', {
        appearance: 'success',
        autoDismiss: false,
      });
    } else {
      addToast(result.message, {
        appearance: 'error',
        autoDismiss: false,
      });
    }
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <Card className="striped-tabled-with-hover">
      				<Card.Body>
                
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Card className="striped-tabled-with-hover">
      				<Card.Header>Lug Information</Card.Header>
      				<Card.Body>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column xs={12} >Name</Form.Label>
                  <Col xs={12}>
                    <Form.Control type="text" name="name" value={lug.name} onChange={handleInputChange}/>  
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column xs={12} >Lug Year</Form.Label>
                  <Col xs={12}>
                    <Form.Control type="text" name="lug_year" value={lug.lug_year} onChange={handleInputChange}/>  
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column xs={12} >Lug Admins</Form.Label>
                  <Col xs={12}>
                    <Form.Select type="select" multiple name="admins" onChange={handleInputChange} value={lugAdmins} >
                      <option></option>
                      {users.map((l, i) => {
                        return (<option key={i} value={l.id}>{l.username}</option>);
                      })}
                    </Form.Select>
                  </Col>
                </Form.Group>
                {/* <Form.Group as={Row} className="mb-3">
                  <Form.Label column xs={12} >Upload Parts</Form.Label>
                  <Col xs={12}>
                    <FileUpload/>  
                  </Col>
                </Form.Group> */}
              </Card.Body>
              <Card.Footer>
                <Button variant="primary" onClick={handleSave}>Save</Button>
              </Card.Footer>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card className="striped-tabled-with-hover">
      				<Card.Header>User Voting Rights</Card.Header>
      				<Card.Body>
                <Form as={Row}>
                  <Col xs={12} sm={6}>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column xs={12} >Part Limit per User</Form.Label>
                      <Col xs={4} sm={3}>
                        <Form.Control type="number" name="part_limit" value={lug.part_limit == null ? '': lug.part_limit} onChange={handleInputChange}/>  
                      </Col>
                    </Form.Group>
                  </Col>
                </Form>
                <Form as={Row}>
                  <Col xs={12} sm={6}>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column xs={12} >Nomination Limit per User</Form.Label>
                      <Col xs={4} sm={3}>
                        <Form.Control type="number" name="nominate_limit" value={lug.nominate_limit == null ? '': lug.nominate_limit} onChange={handleInputChange}/>  
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={6}>
                    <Form.Group as={Row} className="mb-3">
                    <Form.Label column xs={12} >Nomination End Date</Form.Label>
                    <Col xs={12} sm={6}>
                        <Form.Control type="date" name="nominate_end" value={lug.nominate_end == null ? '': lug.nominate_end} onChange={handleInputChange}/>  
                      </Col>
                    </Form.Group>
                  </Col>
                </Form>
                <Form as={Row}>
                  <Col xs={12} sm={6}>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column xs={12} >Vote Limit per User</Form.Label>
                      <Col xs={4} sm={3}>
                        <Form.Control type="number" name="vote_limit" value={lug.vote_limit == null ? '': lug.vote_limit} onChange={handleInputChange}/>  
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={6}>
                    <Form.Group as={Row} className="mb-3">
                    <Form.Label column xs={12} >Vote End Date</Form.Label>
                    <Col xs={12} sm={6}>
                        <Form.Control type="date" name="vote_end" value={lug.vote_end == null ? '': lug.vote_end} onChange={handleInputChange}/>  
                      </Col>
                    </Form.Group>
                  </Col>
                </Form>
                <Form as={Row}>
                  <Col xs={12} sm={6}>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column xs={12} >Order Limit</Form.Label>
                      <Col xs={4} sm={3}>
                        <Form.Control type="number" name="order_limit" value={lug.order_limit == null ? '': lug.order_limit} onChange={handleInputChange}/>  
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={6}>
                    <Form.Group as={Row} className="mb-3">
                    <Form.Label column xs={12} >Order End Date</Form.Label>
                    <Col xs={12} sm={6}>
                        <Form.Control type="date" name="order_end" value={lug.order_end == null ? '': lug.order_end} onChange={handleInputChange}/>  
                      </Col>
                    </Form.Group>
                  </Col>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <LugInvoicing />
        </Row>
      </Container>
    </>
  );
}

export default LugSettings;
import React, { useState, useEffect, useContext } from "react";
import { LugBulkContext } from "../Context/LugBulkContext";
import { Link } from 'react-router-dom';
import { Toast, ToastContainer, Button, Badge } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import PartImage from '../Parts/PartImage';

function UserNotifications() {
  const { context } = useContext(LugBulkContext);
  const token = context.User.token;
  const serverURL = process.env.REACT_APP_SERVER_URL;
  
  const [ notifications, setNotifications ] = useState([]);
  const [ showNotes, setShowNotes ] = useState(false);
  
  const toggleShowNotes = (e) => {
    e.preventDefault();
    setShowNotes(!showNotes);
  }

  useEffect(() => {
		(async () => {
      const resp = await axios.get(serverURL+'/notifications', {headers: {'x-access-token': token }});
			if (resp !== undefined && resp.data !== '') setNotifications(resp.data);
		})();
	}, []);

  const handleRemove = id => async (e) => {
    await axios.put(serverURL+'/notifications/read/' + id, {}, { headers: {'x-access-token': token }}).then(result => {
      const arr = [...notifications];
      const idx = arr.findIndex(n => n.id === id);
      if (idx > -1) arr.splice(idx, 1);
      setNotifications(arr);
    });
  }

  return (
    <>
      <Link onClick={toggleShowNotes} to="/" className="btn">
        <FontAwesomeIcon icon={faBell} size="lg"></FontAwesomeIcon>
        <Badge bg="danger">{notifications.length}</Badge>
      </Link>
      <ToastContainer style={{position: 'absolute', zIndex: 999999, top: 30, right: 30}}>
        <Toast className="nominationToast" show={showNotes} onClose={toggleShowNotes}>
          <Toast.Header closeButton={true}>
            <strong className="mr-auto">Notifications</strong>
          </Toast.Header>
          <Toast.Body>
            {notifications.map(v => {
            return (
              <div key={v.id} className="user-notification">
                
                <span>Part was removed. Please select a new one.</span>
                <PartImage part_id={v.part_id} width={40} style={{paddingRight: 14}}/> 
                
                <FontAwesomeIcon icon={faTimes} onClick={handleRemove(v.id)} role="button"/>
              </div>)
          })}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
}

export default UserNotifications;
import React, { useState, useEffect, useMemo, useContext } from "react";
import { LugBulkContext } from "../Context/LugBulkContext";

import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications'
import DataTable from 'react-data-table-component';
import axios from 'axios';


function LugInvoicing() {
  const { context } = useContext(LugBulkContext);

  const serverURL = process.env.REACT_APP_SERVER_URL;
  const token = context.User.token;
	const lug = context.Lug;

  const [ invoices, setInvoices ] = useState([]);
  const { addToast } = useToasts();

  useEffect(() => {
		(async () => {
      const invoiceData = await axios.get(serverURL+'/lugs/invoices', {headers: {'x-access-token': token }});
			if (invoiceData !== undefined) setInvoices(invoiceData.data);
		})();
	}, []);

  const totalUp = (row) => {
    if (!row) return 0;
    let val = row.part_value + row.shipping_value + row.tax_value;
    return +(Math.round(val + "e+2")  + "e-2");
  }

  const columns = useMemo(
		() => [
				{
					name: "Year", 
          selector: "year",
          sortable: true,
				}, {
					name: "Date", 
					selector: "date", 
					sortable: true,
				}, {
					name: "Part total", 
					selector: "part_value", 
					sortable: false,
          right: true,
					grow: 2,
				}, {
					name: "Shipping", 
					selector: "shipping_value", 
					sortable: false,
          right: true,
				}, {
					name: "Tax", 
					selector: "tax_value", 
					sortable: false,
          right: true,
				}, {
					name: "Total", 
          right: true,
          cell: row => totalUp(row)
				}, {
					name: "Currency", 
					selector: "currency",
					width: "120px",
				}
		], [invoices]
	);

  return (
    <>
      <Col xs={12} md={6}>
        <Card className="striped-tabled-with-hover">
          <Card.Header>Lug Invoices</Card.Header>
          <Card.Body>
          <DataTable 
								columns={columns} 
								data={invoices} 
								pagination
                striped
							/>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}

export default LugInvoicing;
import React from 'react';
import ReactDOM from 'react-dom';

import { LugBulkProvider } from "./components/Context/LugBulkContext";
import { ToastProvider } from "react-toast-notifications";

import App from './App';


function AppContextProvider() {
  return (
    <LugBulkProvider value={500}>
      <ToastProvider>
        <App />
      </ToastProvider>
    </LugBulkProvider>
  );
}

const rootElement = document.getElementById('root');

ReactDOM.render(<AppContextProvider />, rootElement);
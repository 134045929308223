import React, { useEffect, useState, useContext } from 'react';
import { useSetState } from 'react-use';
import { LugBulkContext } from "../Context/LugBulkContext";
import { Form, ButtonGroup, Button } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications'
import axios from 'axios';

import './Login.css';
import loginImg from '../../assets/img/login-image.jpg';
import registerImg from '../../assets/img/register-image.jpg';

const initialState = {
  username: '',
  password: ''
}

const Login = () => {
	const { context: ContextState, login } = useContext(LugBulkContext);
	const {
    User,
		Lug,
		Nominations,
    loginError
  } = ContextState;
	const { addToast } = useToasts();

  const [ state, setState ] = useSetState(initialState);
	const [ toggleForm, setToggleform ] = useState(false);
	const [ toggleLug, setTogglelug ] = useState(false);
	const [ userError, setUsererror ] = useState(false);
	const [ lugs, setLugs ] = useState([]);
	const [ user, setUser ] = useState({
    username: "",
    email: "",
    active: true,
    lug_id: 0,
		lug_name: "",
		password: "",
  });
	const serverURL = process.env.REACT_APP_SERVER_URL;
	
	useEffect(() => {
		(async () => {
      const resp = await axios.get(serverURL+'/lugs/list');
			if (resp && resp.status === 200) setLugs(resp.data);
		})();
	}, []);

	useEffect(() => {
		if (loginError) showError();
	}, [loginError, User]);

	const toggleLoginForm = () => {
		setToggleform(!toggleForm);
	}

	const showError = (msg = 'Invalid username and/or password.') => {
		addToast(msg, { 
			appearance: 'error', 
			autoDismiss: true,
			autoDismissTimeout: 15000
		});
		setUsererror(true);
	}

  const handleLogin = async (e) => {
		e.preventDefault();
		const { username, password } = state;
		login(username, password);
	}

	const handleRegister = async (e) => {
		e.preventDefault();
		if (isBlank(user.username)) showError('Please enter a username.');
		if (validatePassword(user.password)) showError('Password is not valid. Please use at least six (6) characters and combination of upper/lower case and numbers.');
		if (validateEmail(user.email)) showError('Email is not valid. Please use correct email address.');
		
		if (user.lug_id === '' || user.lug_id === 0) showError('Please select a LUG to join.');
		if (user.lug_id === '-1') {
			if (isBlank(user.lug_name)) showError('Please enter a LUG name.');
		}

		if (!userError) {
			await axios.put(serverURL+'/users/register', user).then(resp => {
				if (resp.status === 200 && resp.data !== '') login(user.username, user.password);
			})
		}
	}

	const handleInputChange = async (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

		if (name === 'lug_id') setTogglelug((value === "-1"));
    setUser({...user, 
      [name]: value
    });
  }

	const validateEmail = (email) => {
		return !testRegex(email.toLowerCase(), '[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$');
	}

	const validatePassword = (password) => {
		return !testRegex(password, '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})');
	}

	const isBlank = (string) => {
		return testRegex(string, '^\s*$');
	}

	const testRegex = (needle, theRegex) => {
		const haystack = new RegExp(theRegex);
		return haystack.test(needle); 
	}

	if (!toggleForm) {
		return(
			<>
				<div className={'loginContainer'}>
					<div className={'loginWrapper'}>
						<div className={'loginImage'}>
							<img src={loginImg} width={300} style={{position: 'relative'}} alt="login"/>
						</div>
						<div className={'loginForm'}>
							<h2>LUGBulk Login</h2>
							<Form onSubmit={handleLogin}>
								<Form.Group controlId="formBasicEmail" className="mb-3">
									<Form.Label>Username</Form.Label>
									<Form.Control type="text" placeholder="Enter username" onChange={e => setState({username: e.target.value})} autoFocus="autoFocus"/>
								</Form.Group>
								<Form.Group controlId="formBasicPassword" className="mb-4">
									<Form.Label>Password</Form.Label>
									<Form.Control type="password" placeholder="Password" onChange={e => setState({password: e.target.value})} />
								</Form.Group>	
								<div className="btngroup">
									<Button variant="primary" type="submit">Login</Button>
									<Button variant="secondary" onClick={toggleLoginForm}>Register</Button>
								</div>
							</Form>
						</div>
					</div>
				</div>
			</>
		);
	} else {
		return(
			<>
				<div className={'loginContainer'}>
					<div className={'loginWrapper'}>
					<div className={'loginImage'}>
							<img src={registerImg} width={300} style={{position: 'relative'}} alt="login"/>
						</div>
						<div className={'loginForm'}>
							<h2>LUGBulk Register</h2>
							<Form onSubmit={handleRegister}>
								<Form.Group controlId="formBasicUsername" className="mb-3">
									<Form.Label>Username</Form.Label>
									<Form.Control type="text" placeholder="Enter username" name="username" value={user.username} onChange={handleInputChange} autoFocus="autoFocus"/>
								</Form.Group>
								<Form.Group controlId="formBasicPassword" className="mb-4">
									<Form.Label>Password</Form.Label>
									<Form.Control type="password" placeholder="Password" name="password" value={user.password} onChange={handleInputChange} />
								</Form.Group>	
								<Form.Group controlId="formBasicEmail" className="mb-3">
									<Form.Label>Email</Form.Label>
									<Form.Control type="text" placeholder="Your email" name="email" value={user.email} onChange={handleInputChange} />
								</Form.Group>
								<Form.Group controlId="formSelectLug" className="mb-4">
									<Form.Label>Select existing LUG</Form.Label>
									<Form.Select type="select" placeholder="Select LUG" name="lug_id" onChange={handleInputChange} >
										<option></option>
										<option key={-1} value={-1}>Create LUG</option>
										{lugs.map((l, i) => {
											return (<option key={i} value={l.id}>{l.name}</option>);
										})}
									</Form.Select>
								</Form.Group>
								{toggleLug ? 
								<Form.Group controlId="formBasicLugName" className="mb-3">
									<Form.Label>LUG Name</Form.Label>
									<Form.Control type="text" placeholder="Enter LUG name" name="lug_name" value={user.lug_name} onChange={handleInputChange} />
								</Form.Group>
								: '' }
								<div className="btngroup">
									<Button variant="primary" type="submit">Save</Button>
									<Button variant="secondary" onClick={toggleLoginForm}>Back to Login</Button>
								</div>
							</Form>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Login;
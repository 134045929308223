import React, { useContext } from 'react';
import { LugBulkContext } from "./components/Context/LugBulkContext";
import { BrowserRouter as Router } from 'react-router-dom';

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/lugbulk-css.css";

import Login from './components/Users/Login';
import Header from './components/App/Header';                                  
import Content from './components/App/Content';
import Sidebar from './components/App/Sidebar';
import Footer from './components/App/Footer';

const App = () => {

	const { context } = useContext(LugBulkContext);	
	
  if(!context || !('token' in context.User)) {
      return <Login />;
	} else {
		return (
			<>
			<Router>
				<div className="wrapper">
					<Sidebar />
					<div className="main-panel">
						<Header />
						<div className="content">
							<Content />
						</div>
						<Footer />
					</div>
				</div>
			</Router>
			</>
		);
	}
}

export default App;
import React, { useState, useEffect, useContext } from "react";
import { LugBulkContext } from "../Context/LugBulkContext";

import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';

import AdminBar from '../Settings/AdminBar';
import OrderList from '../Orders/OrderList';
import NominatedList from '../Parts/NominatedList';


function Lug() {
  const { context } = useContext(LugBulkContext);
  const token = context.User.token;
  const serverURL = process.env.REACT_APP_SERVER_URL;
  
  const [ users, setUsers ] = useState([]);
  const [ lug, setLug ] = useState([]);

  useEffect(() => {
		(async () => {
      const lugsData = await axios.get(serverURL+'/lugs', {headers: {'x-access-token': token }});
			if (lugsData !== undefined) setLug(lugsData.data);
			const userresp = await axios.get(serverURL+'/users', {headers: {'x-access-token': token }});
			if (userresp !== undefined) setUsers(userresp.data);
		})();
	}, []);

  return (
    <>
      <AdminBar users={users} lug={lug} />
      <Container fluid>
        <Row>
          <Col xs="12" md="6">
            <OrderList year={lug.lug_year} lug={lug.id} />
          </Col>
          <Col xs="12" md="6">
            <NominatedList year={lug.lug_year} lug={lug.id} />  
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Lug;
import React, { useState, useEffect, useContext, useMemo } from "react";
import { LugBulkContext } from "../Context/LugBulkContext";

import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import CustomLoader from '../Widgets/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

function UserOrderSummary() {
  const { context } = useContext(LugBulkContext);
	const token = context.User.token;

  const serverURL = process.env.REACT_APP_SERVER_URL;
  const rate = process.env.REACT_APP_CONVERSION_RATE;
  const tax  = process.env.REACT_APP_DEFAULT_TAXRATE;
  const shipping = process.env.REACT_APP_EST_SHIP_PERCENT;

  const [ pending, setPending ] = useState(true);
  const [ orders, setOrders ] = useState([]);

  useEffect(() => {
		(async () => {
      await axios.get(serverURL+'/orders/my-orders', {headers: {'x-access-token': token }}).then(resp => {
        if (resp) {
          setOrders(resp.data);
          setPending(false);
        }
      })
		})();
	}, []);

  const getConvertedTotal = (r) => {
    let _totalorder = parseFloat(r.total_value)
    let _totalwship = _totalorder + (_totalorder * parseFloat(shipping));
    let _totalwtax = _totalwship + (_totalwship * parseFloat(tax));
		return (_totalwtax * parseFloat(rate)).toFixed(2);
	}

  const TableActions = ({ row }) => {
		return (
			<>
				<EditLink year={row.year}></EditLink>
				{/* <Button onClick={() => { handleDelete(row.id)}} variant="link" size="sm"><FontAwesomeIcon icon={faTrashAlt} color="black"/></Button> */}
			</>);
	};

  const EditLink = ({year, text = ''}) => {
		let html = (!text || text === '')? <FontAwesomeIcon icon={faEye} color="black"/>: text;
		return (<Link to={'/orders/user/year/' + year} >{html}</Link>)
	};


  const columns = [
		{
			name: "Year",
			selector: "year",
			sortable: true
		},
		{
			name: "Part Count",
			selector: "total_parts",
			sortable: true
		},
		{
			name: "Quantity",
			selector: "total_qty",
			sortable: true
		},
		{
			name: "Value",
			selector: "total_value",
			sortable: true,
		},
		{
			name: "Total AUD (inc Tax & Ship)",
			cell: row => getConvertedTotal(row),
		},
    {
      name: "Actions",
      cell: row => <TableActions row={row} />,
      width: "80px"
    }
	];

  return (
    <>
			<DataTable 
				columns={columns} 
				data={orders} 
				progressPending={pending}
				progressComponent={<CustomLoader />}
				striped
				noHeader
			/>
    </>
  );

}

export default UserOrderSummary;
import React, { createContext } from "react";
import { useSetState } from 'react-use';
import { useHistory } from "react-router";
import axios from 'axios';
import jwt_decode from "jwt-decode";

export const LugBulkContext = createContext(null);

const initialState = {
   User: {},
   Nominations:  [],
   Lug: {},
   loginError: null
};
const serverURL = process.env.REACT_APP_SERVER_URL;

let userToken = '';

const fetchUser = async (credentials, callback) => {
   await axios.post(serverURL+'/users/login', {
      credentials
   }).then(resp => {
      if (resp.status === 200 && resp.data.token) {
         userToken = resp.data.token;
         callback(false);
      } else {
         callback(true);
      }
   }).catch(err => {
      callback(true);
   })
}

export const LugBulkProvider = props => {
   const history = useHistory();
   const [context, setContext] = useSetState(initialState);
   const setLoginError = async (loginError) => {
      setContext(prev => ({
         ...prev, 
         loginError,
      }));
   }
   const setUserContext = async (User) => {
      setContext(prev => ({
         ...prev, 
         User,
      }));
   }
   const setLugContext  = async (Lug)  => {
      setContext(prev => ({
         ...prev, 
         Lug,
      }));
   }
   const setNominations = async (Nominations) => {
      setContext(prev => ({
         ...prev, 
         Nominations,
      }));
   }

   const login = (username, password) => {
      fetchUser({username, password}, error => {
         setLoginError(null);
         if (!error && userToken !== '') {
            setUserContext({...jwt_decode(userToken), token: userToken});
            history.push('/');
         }
         setLoginError(error);
      });
   }

   const logout = () => {
      setUserContext({});
      setLugContext({});
      setNominations([]);
   }

   return (
      <LugBulkContext.Provider value={{context, login, logout, setLugContext, setNominations}}>
         {props.children}
      </LugBulkContext.Provider>
   );
};

export const LugBulkConsumer = LugBulkContext.Consumer;
import React, { useState, useEffect, useContext } from "react";
import { LugBulkContext } from "../Context/LugBulkContext";

import BTable from 'react-bootstrap/Table';
import { Container, Row, Col, Card, Dropdown, DropdownButton, Button, Toast } from 'react-bootstrap';
import { useTable } from 'react-table';
import { useToasts } from 'react-toast-notifications'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import PartImage from './PartImage';

function Votes() {
	const { context } = useContext(LugBulkContext);
	const { addToast } = useToasts();

  const [ data, setData ] = useState([]);
  const [ votes, setVotes ] = useState([]);
  
	const user = context.User;
	const token = context.User.token;
	const lug = context.Lug;
	const today = new Date().toISOString().split('T')[0];
	const lugVoteEnd = lug.vote_end;
	const serverURL = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
		(async () => {
			const resp = await axios.get(serverURL+'/votes', {headers: {'x-access-token': token }});
			if (resp !== undefined) setData(resp.data);
		})();
  }, []);

  const columns = [{
		Header: 'Parts',
		columns: [
			{Header: "Select", Cell: (cellInfo => {
				return (<>
					{today <= lugVoteEnd ?
						<>
							<p>Vote for this part</p>
							<DropdownButton variant="primary" id="dropdown-basic" size="sm" title="Vote weight" onSelect={handleSelect(cellInfo.cell.row.original, votes)}>
								{votes.findIndex(v => v.weight === 3) === -1 && <Dropdown.Item eventKey="3">Highest</Dropdown.Item>}
								{votes.findIndex(v => v.weight === 2) === -1 && <Dropdown.Item eventKey="2">Medium</Dropdown.Item>}
								{votes.findIndex(v => v.weight === 1) === -1 && <Dropdown.Item eventKey="1">Lowest</Dropdown.Item>}
							</DropdownButton>
						</>
					: <p>Voting is now closed.</p> }</>
			)})},
			{Header: "Image", Cell: (cellInfo => {
				return (<PartImage part_id={cellInfo.cell.row.original.id} width={100} />)
			})},
			{Header: "Part Id", accessor: "id", sortType: "basic"},
			{Header: "Title", accessor: "title", sortType: "basic"},
			{Header: "Lego Colour", accessor: "color.title", sortType: "basic", },
			{Header: "BL Colour", accessor: "blcolor.title", sortType: "basic",},
			{Header: "Price", accessor: "price" },
			{Header: "Currency", accessor: "currency" },
			{Header: "Price AUD", Cell: (cellInfo => {
				const exch = (cellInfo.cell.row.original.price * 1.6522).toFixed(4);
				return ( <> {exch} </> );
			})},
		]
  }];

  const handleSelect = part => async (e) => {
	  let obj = { part_id: part.id, weight: e, name: part.title, year: part.year };
	  let arr = [...votes];
	  let idx = arr.findIndex(v => v.part_id === obj.part_id);
		let _limit = lug.vote_limit;
		if (user && user.vote_limit) _limit = user.vote_limit;

	  switch (true) {
		  case (arr.length < _limit && idx === -1):
		    setVotes(votes => [...votes, obj]);
		  break;
		  case (arr.length < _limit && idx !== -1):
		    addToast('You have already added part ' + part.id, {
					appearance: 'warning', 
					autoDismiss: false,
		    });
		  break;
		  default:
		  	addToast('You have reached your limit of (' + _limit + ') votes', {
					appearance: 'error', 
					autoDismiss: false,
		    });
	  }
  }

  const handleSave = async (e) => {
		let _limit = lug.vote_limit;
		if (user && user.vote_limit) _limit = user.vote_limit;
	  if (votes.length <= _limit) {
		  const result = await axios.put("votes", votes, { headers: {'x-access-token': token }});
		  if (result.status === 200) {
			  setVotes([]);
			  addToast('Your votes have been successfully saved!', {
					appearance: 'success',
					autoDismiss: false,
        });
		  } else {
			  addToast(result.message, {
				  appearance: 'error',
				  autoDismiss: false,
        });
		  }
      }
  }

  const handleRemove = part_id => async (e) => {
	 let arr = [...votes];
	 let idx = arr.findIndex(v => v.part_id === part_id);
	 if (idx !== -1) {
	     arr.splice(idx, 1);
		 setVotes(arr);
	 }
  }

  function Table({ columns, data }) {
		const {
			getTableProps,
			getTableBodyProps,
			headerGroups,
			rows,
			prepareRow,
			visibleColumns,
			} = useTable(
			{
			columns,
			data,
			},
		);

		return (
			<BTable striped bordered hover size="sm" {...getTableProps()}>
				<thead>
					{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(column => (
								<th>
									{column.render("Header")}
								</th>
							))}
						</tr>
					))}
					<tr>
						<th
							colSpan={visibleColumns.length}
							style={{
								textAlign: 'left',
							}}
						>
						</th>
						</tr>
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row, i) => {
						prepareRow(row)
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map(cell => {
									return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
								})}
							</tr>
						)
					})}
				</tbody>
			</BTable>
		);
	}

  function Notify({items, errors}) {
	  return (
		  <>{items.length > 0 &&
		  <Toast
		  style={{
		        position: 'fixed',
		        top: 20,
		        right: 20,
		        zIndex: 876,
		        minWidth: 290,
		       }}
		  >
		    <Toast.Header closeButton={false}>
			    <strong className="mr-auto">Voted Parts</strong>
			  </Toast.Header>
			  <Toast.Body>
			  {items.sort((a,b) => (a.weight > b.weight) ? -1 : ((b.weight > a.weight) ? 1 : 0)).map(v => {
					return (
						<div key="{v}" style={{position: 'relative', paddingRight: 25, fontSize: 12}}>
							<PartImage part_id={v.part_id} width={40} style={{paddingRight: 14}}/>
							{v.name}
							<FontAwesomeIcon icon={faTimes} onClick={handleRemove(v.part_id)} style={{position: 'absolute', right: 0, cursor: 'pointer'}}/>
						</div>)
				})}
				<div style={{paddingTop: 20}}>
					<Button onClick={handleSave} variant="primary" size="sm">Save</Button>
				</div>
			  </Toast.Body>
		  </Toast>
	  	  }</>
	  )
  }

  return (
  	<>
  	  <Notify items={votes}/>
      <Container fluid>
      	<Row>
      		<Col md="12">
      			<Card className="striped-tabled-with-hover">
      				<Card.Header>Vote for Parts</Card.Header>
      				<Card.Body className="table-full-width table-responsive px-0">
      					<Table columns={columns} data={data} striped bordered hover/>
      				</Card.Body>
      			</Card>
      		</Col>
      	</Row>
      </Container>
    </>
  );
}

export default Votes;
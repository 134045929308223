import React from 'react';

function PartImage({part_id, width = 100}) {
  const imgsrc = 'bg-noimage.jpg';

  return (
    <>
      <img src={`${process.env.PUBLIC_URL}/assets/img/parts/${part_id}.jpg`} 
           alt={part_id} 
           width={width} 
           responsive="true" 
           onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src=process.env.PUBLIC_URL+'/assets/img/'+imgsrc;
           }}
      />
    </>
  )

}

export default PartImage;
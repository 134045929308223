import React from "react";
import { Container } from "react-bootstrap";

function Footer() {
    return (
	  <>
      <footer className="footer px-0 px-lg-3">
        <Container fluid>
          <nav>
            <p className="copyright text-center">
              &copy; {new Date().getFullYear()}{" "}
              Made by a Lego lover...
            </p>
          </nav>
        </Container>
      </footer>
      </>
    );
}

export default Footer;
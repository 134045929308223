import React, { useState, useEffect, useContext } from "react";
import { LugBulkContext } from "../Context/LugBulkContext";
import { Link, useHistory  } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Card, Image, Nav, Badge, Button } from 'react-bootstrap';
import Moment from 'react-moment';

import AdminBar from '../Settings/AdminBar';

function Dashboard() {
	const { context, setLugContext } = useContext(LugBulkContext);
	const [ votable, setVotable] = useState([]);
	const [ ifVoting, setIfVoting ] = useState(false);
	const [ nominated, setNominated] = useState([]);
	const [ newpartcount, setNewpartcount] = useState(0);
	const navigate = useHistory ();

	const currentuser = context.User;
	const token = context.User.token;
	const admin = (currentuser.id === currentuser.lug_adm);
	const lug = context.Lug;
	const serverURL = process.env.REACT_APP_SERVER_URL;
	
	const capitaliseFirstLetter = ([ first, ...rest ], locale = navigator.language) =>
	  first.toLocaleUpperCase(locale) + rest.join('')

	const getFirstName = () => {
		return capitaliseFirstLetter(currentuser.username.split('.')[0]);
	}

	const partLimit = () => {
		let _limit = 0;
		if (lug && lug.part_limit){
			_limit = lug.part_limit;
		}
		if (lug && lug.nominate_limit){
			_limit = lug.nominate_limit;
		}
		if (currentuser && (currentuser.nominate_limit === 0 || currentuser.nominate_limit > 0)) {
			_limit = currentuser.nominate_limit;
		}
		return _limit;
	}

	useEffect(() => {
		(async () => {
			setNewpartcount(0);
			const lugsData = await axios.get(serverURL+'/lugs', {headers: {'x-access-token': token }});
			setLugContext(lugsData.data);
			const voteParts = await axios.get(serverURL+'/votes/selected', {headers: {'x-access-token': token }});
			setVotable(voteParts.data);
			const nomParts = await axios.get(serverURL+'/parts/lug/nominated', {headers: {'x-access-token': token }});
			setNominated(nomParts.data);
			const newParts = await axios.get(serverURL+'/parts/newcount', {headers: {'x-access-token': token }});
			setNewpartcount(newParts.data.count);
		})();
	}, []);

	useEffect(() => {
		let _out = (!lug.vote_end);
		let _date = new Date();
		let _chkdate = new Date(lug.nominate_end); 
		_out = (votable.length > 0 && (+_chkdate < +_date));
		setIfVoting(_out);
	}, [votable]);

	useEffect(() => {
		if ((lug.lug_year && lug.lug_year === 0) && admin) navigate.push('/lug/settings');
	}, [lug, admin]);

  function PartsList({parts}) {
		return (
			<>
			{parts.length > 0 &&
				<Row xs={2} sm={4} md={ifVoting ? 4: 6}>
					{parts.map(p => {
					return (
						<>
						<Col style={{padding: '10px'}} key={p.part.id} >
							<Card style={{padding: '10px'}}>
								<Card.Img variant="top" src={`${process.env.PUBLIC_URL}/assets/img/parts/${p.part.id}.jpg`} />
								<Card.Body>
								  <Card.Text style={{textAlign: 'center'}}>
									{p.part.title}
								  </Card.Text>
								  {p.part_count && <Button variant="primary" size="xs" block>Weight/Votes <Badge variant="light">{p.total_weight} / {p.part_count}</Badge></Button>}
								</Card.Body>
							</Card>
						</Col>
						</>
					)})}
				</Row> }
			</>
		);
	}

	return (
		<>
			{admin && <AdminBar admin={admin} /> }
		  <Container fluid>
		  	<Row>
		  		<Col sm={7}>
		  			<Card  style={{minHeight: 235}}>
		  				<Card.Header>Getting Started</Card.Header>
						<Card.Body>
							<p> Welcome to LUGBulk, {getFirstName()}</p>
							<ol>
								<li>Nominate {partLimit()} parts. {partLimit() !== 0 && <strong>Nominations closes on {lug.nominate_end && <Moment format='Do MMMM YYYY' >{lug.nominate_end}</Moment>}.</strong>}</li>
								{lug.vote_limit >= 1 && <li>Vote from a selected list of parts (if voting selected). <strong>Voting closes on {lug.vote_end && <Moment format='Do MMMM YYYY' >{lug.vote_end}</Moment>}.</strong></li>}
								<li>Order from the selected parts. <strong>Orders close on {lug.order_end && <Moment format='Do MMMM YYYY' >{lug.order_end}</Moment>}.</strong></li>
								<li>Enjoy building...</li>
							</ol>
    					</Card.Body>
    				</Card>
		  		</Col>
					<Col xs={12} sm={5} md={3}>
		  			<Card  style={{minHeight: 235}}>
						<Card.Header>New Parts
							<Nav className="ml-auto" defaultActiveKey="/parts/new">
								<Nav.Item>
									<Nav.Link as={Link} to="/parts/new">View</Nav.Link>
								</Nav.Item>
							</Nav>
						</Card.Header>
						<Card.Body>
							<center><span style={{fontSize:32}}>{newpartcount}</span><br/>new parts have been added this year!</center>
						</Card.Body>
    				</Card>
		  		</Col>
		  		<Col md={2} className="d-none d-md-block">
		  			<Card  style={{minHeight: 235}}>
						<Card.Body>
							<Image src={lug && lug.logo} rounded fluid="true"/>
						</Card.Body>
    				</Card>
		  		</Col>
		  	</Row>
		  	<Row>
				{ ifVoting && <>
				<Col xs={12} lg={6}>
					<Card >
						<Card.Header>Vote for parts
							<Nav className="ml-auto" defaultActiveKey="/voting">
								<Nav.Item>
									<Nav.Link as={Link} to="/voting">Vote Now</Nav.Link>
								</Nav.Item>
							</Nav>
						</Card.Header>
						<Card.Body>
							{votable.length === 0 && <p> No parts have been voted for yet.</p> }
							{votable.length > 0 && <p> Here's the most popular parts voted for...</p> }
							<PartsList parts={votable} />
						</Card.Body>
					</Card>
				</Col>
				</>}
				<Col xs={12} lg={ifVoting? 6: 12}>
					<Card >
						<Card.Header>Nominated parts
							<Nav className="ml-auto" defaultActiveKey="/ordering">
								<Nav.Item>
									<Nav.Link as={Link} to="/ordering">Order Now</Nav.Link>
								</Nav.Item>
							</Nav>
						</Card.Header>
						<Card.Body>
							{nominated.length === 0 && <p> No parts to order.</p> }
							<PartsList parts={nominated} />
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	</>
	);
}

export default Dashboard;
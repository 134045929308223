import React, { useState, useEffect, useContext } from "react";
import { LugBulkContext } from "../Context/LugBulkContext";
import { Link } from 'react-router-dom';
import { Toast, ToastContainer, Button, Badge } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications'
import axios from 'axios';
import PartImage from '../Parts/PartImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTasks, faTimes } from "@fortawesome/free-solid-svg-icons";

function NominatedPartUser({ addPart }) {
  const { context, setNominations } = useContext(LugBulkContext);
  const { addToast } = useToasts();

  const currentuser = context.User;
	const token = context.User.token;
	const lug = context.Lug;
  const serverURL = process.env.REACT_APP_SERVER_URL;
  
  const [showParts, setShowParts] = useState(false);
  
  const toggleShowParts = (e) => {
    e.preventDefault();
    setShowParts(!showParts);
  }

  useEffect(() => {
		(async () => {
      await axios.get(serverURL+'/parts/user/nominated', {headers: {'x-access-token': token }}).then(resp => {
      if (resp !== undefined && resp.data !== '') {
        let _noms = [];
        resp.data.map(n => {
          let _obj = { part_id: n.part.id, title: n.part.title, year: n.part.year};
          _noms.push(_obj);
        });
        setNominations(_noms);
      }
      });
		})();
	}, []);

  useEffect(() => {
    if (context.Nominations && context.Nominations.length > 0) {
      if (!showParts) setShowParts(!showParts);
    }
  }, [context.Nominations]);

  useEffect(() => {
    if (addPart) {
      if (!showParts) setShowParts(!showParts);
      
    }
  }, [addPart]);

  const handleRemove = part_id => async (e) => {
    if (context.Nominations && context.Nominations.length > 0) {
      let arr = [...context.Nominations];
      let idx = arr.findIndex(v => v.part_id === part_id);
      if (idx !== -1) {
        arr.splice(idx, 1);
      }
      setNominations(arr);
    } 
  }

  const handleSave = async (e) => {
		let _limit = lug.part_limit;
		if (currentuser && currentuser.nominate_limit) _limit = currentuser.nominate_limit;
	  if (context.Nominations.length <= _limit) {
		  const result = await axios.put(serverURL+'/parts/nominated', context.Nominations, { headers: {'x-access-token': token }});
		  if (result.status === 200) {
        setShowParts(!showParts);
			  addToast('Your nominations have been successfully saved!', {
					appearance: 'success',
					autoDismiss: true,
          autoDismissTimeout: 10000
        });
		  } else {
			  addToast(result.message, {
				  appearance: 'error',
				  autoDismiss: true,
          autoDismissTimeout: 10000
        });
		  }
		}
  }

  return (
    <>
      <Link onClick={toggleShowParts} to="/" className="btn">
        <FontAwesomeIcon icon={faTasks} size="lg"></FontAwesomeIcon>
        <Badge bg="success">{context.Nominations.length}</Badge>
      </Link>
      <ToastContainer style={{position: 'absolute', zIndex: 999999, top: 30, right: 30}}>
        <Toast className="nominationToast" show={showParts} onClose={toggleShowParts}>
          <Toast.Header closeButton={true}>
            <strong className="mr-auto">Nominated Parts</strong>
          </Toast.Header>
          <Toast.Body>
          {context.Nominations && context.Nominations.legnth > 0 }
            {context.Nominations.map(v => {
            return (
              <div key={v.part_id} style={{position: 'relative', paddingRight: 25, fontSize: 12}}>
                <PartImage part_id={v.part_id} width={40} style={{paddingRight: 14}}/>
                {v.title}
                <FontAwesomeIcon icon={faTimes} onClick={handleRemove(v.part_id)} style={{position: 'absolute', right: 0, cursor: 'pointer'}}/>
              </div>)
          })}
          <div style={{paddingTop: 20}}>
            <Button onClick={handleSave} variant="primary" size="sm">Save</Button>
          </div>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
}

export default NominatedPartUser;
import React, { useState, useEffect, useContext, useMemo } from "react";
import { LugBulkContext } from "../Context/LugBulkContext";
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import PartImage from '../Parts/PartImage';
import OrderList from "./OrderList";
import CustomLoader from '../Widgets/Loading';

const convertArrayOfObjectsToCSV = (data, header) => {
  let result;
  
  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  //const keys = Object.keys(data[0]);
  result = '';
  result += header.map(h => h.name).join(columnDelimiter);
  //result += keys.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach(item => {
    let ctr = 0;
    header.forEach(h => {
      if (ctr > 0) result += columnDelimiter;
      switch (h.selector) {
        case 'total': 
          result += '"' + (item['total_parts'] * item['price']) + '"';
          break;
        case 'image':
          result += '""';
          break;
        default:
          result += '"' + item[h.selector] + '"';
      }
      
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

const downloadCSV = (data, headers) => {
  
  const link = document.createElement('a');
  let csv = convertArrayOfObjectsToCSV(data, headers);
  if (csv == null) return;
  const dtestr = new Date().getTime().toString();
  const filename = 'order_list_'+dtestr+'.csv';

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute('href', encodeURI(csv));
  link.setAttribute('download', filename);
  link.click();
}

const Export = ({ onExport }) => (
  <Button size="sm" onClick={e => onExport(e.target.value)}>Export Order Sheet</Button>
);

function Orders() {
  const { context } = useContext(LugBulkContext);
	const user = context.User;
	const token = context.User.token;
	const lug = context.Lug;
  const serverURL = process.env.REACT_APP_SERVER_URL;
  
  const [ orders, setOrders ] = useState([]);
  const [ ordersTotal, setOrdersTotal ] = useState(0);
  const [ pending, setPending ] = useState(true);

  useEffect(() => {
		(async () => {
      await axios.get(serverURL+'/orders/parts', { headers: {'x-access-token': token }}).then(resp => {
				setOrders(resp.data);	
			}).then(setOrdersTotal => {
				setPending(false);
			});
		})();
	}, []);

  useEffect(() => {
    setOrdersTotal(orders.map(o => (o.total_parts * o.price)).reduce((sum, val) => sum + val, 0));
  }, [orders]);

  const calculateLineTotal = (price, qty) => {
    if (!qty) return 0;
    return roundCalculation((qty * price));
  }

  const roundCalculation = (val) => {
    return +(Math.round(val + "e+2")  + "e-2");
  }

  const columns = [
		{
			name: "Image",
      selector: "image",
			cell: row => <PartImage part_id={row.part_id} width={40} />
		},
		{
			name: "Part Id",
			selector: "part_id",
			sortable: true
		},
    {
			name: "Part Name",
			selector: "part_title",
			sortable: true
		},
    {
			name: "Part Colour",
			selector: "part_color",
			sortable: true
		},
    {
			name: "Quantity",
			selector: "total_parts",
			sortable: true
		},
    {
			name: "Unit Price",
			selector: "price",
			sortable: true
		},
    {
			name: "Total",
      selector: "total",
      right: true,
      cell: row => calculateLineTotal(row.price, row.total_parts).toFixed(2)
		},
  ];

  const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(orders, columns)} />, [orders, columns]);

  return (
    <>
    <Container fluid>
      <Row>
        <Col xs={12} md={7}>
          <Card className="strpied-tabled-with-hover">
            <Card.Header>Parts ordered for {lug.lug_year}</Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
            <DataTable 
                columns={columns} 
                data={orders} 
                striped
                highlightOnHover
                actions={actionsMemo}
                progressPending={pending}
                progressComponent={<CustomLoader />}
            />
            <div className="orderTotal">
              <span>LUG Min {roundCalculation(process.env.REACT_APP_LUG_MIN_ORDER)} </span>
              <span>LUG Order Total [{lug.lug_year}] {ordersTotal}</span>
            </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={5}>
          <OrderList lug={lug.id} year={lug.lug_year}/>
        </Col>
      </Row>
    </Container>
    </>
  );
}

export default Orders;
import React, { useMemo, useState, useEffect, useContext } from "react";
import { LugBulkContext } from "../Context/LugBulkContext";

import DataTable from 'react-data-table-component';
import { Container, Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import PartImage from './PartImage';
import CustomLoader from '../Widgets/Loading';

function PartsNew() {
	const { context } = useContext(LugBulkContext);
  const [data, setData] = useState([]);
	const token = context.User.token;
	const serverURL = process.env.REACT_APP_SERVER_URL;

	const [ pending, setPending ] = useState(true);

  useEffect(() => {
		(async () => {
			await axios.get(serverURL+'/parts/new', { headers: {'x-access-token': token }}).then(resp => {
				setData(resp.data);	
				setPending(false);
			});
		})();
  }, []);

	const estimateFX = (amount) => {
		return (amount * 1.6522).toFixed(4);
	}

  const columns = useMemo(
      () => [
          {
						name: "Image", 
						cell: row => <PartImage part_id={row.id} width={100} />
					}, {
						name: "Part Id", 
						selector: "id", 
						sortable: true,
					}, {
						name: "Design Id", 
						selector: "design_id", 
						sortable: true,
					}, {
						name: "Title", 
						selector: "title", 
						sortable: true,
					}, {
						name: "Category", 
						selector: "category", 
						sortable: true,
					}, {
						name: "Lego Colour", 
						selector: "color.title", 
						sortable: true,
					}, {
						name: "BL Colour", 
						selector: "blcolor.title", 
						sortable: true,
					}, {
						name: "Price", 
						selector: "price",
						sortable: true,
					}, {
						name: "Currency", 
						selector: "currency" 
					}, {
						name: "Price AUD", 
						cell: row => estimateFX(row.price)
					},
		], []
  );

  return (
	<>
    <Container fluid>
    	<Row>
    		<Col md="12">
    			<Card className="striped-tabled-with-hover">
    				<Card.Header>New Parts this Year</Card.Header>
    				<Card.Body className="table-full-width table-responsive px-0">
							<DataTable 
								columns={columns} 
								data={data} 
								progressPending={pending}
								progressComponent={<CustomLoader />}
								pagination
							/>
    				</Card.Body>
    			</Card>
    		</Col>
    	</Row>
    </Container>
    </>
  );
}

export default PartsNew;
import React, { useState, useEffect, useContext } from "react";
import { LugBulkContext } from "../Context/LugBulkContext";
import { Link } from 'react-router-dom';
import { Card, Dropdown, Button, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

import axios from 'axios';
import PartImage from '../Parts/PartImage';
import Moment from 'react-moment';
import { useToasts } from 'react-toast-notifications'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { confirm } from 'react-bootstrap-confirmation';

const CheckInput = ({value: initialValue, index, handleChecked, }) => {
  const [value, setValue] = useState(initialValue);

  const onChange = e => {
    setValue(e.target.checked)
  }

  const onBlur = async (e) => {
    handleChecked(index, value);
  }

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue])

  return <Form.Check type="switch" checked={value} onChange={onChange} onBlur={onBlur} />
}


function NominatedList({lug, year}) {
	const { context } = useContext(LugBulkContext);
	const { addToast } = useToasts();
	const serverURL = process.env.REACT_APP_SERVER_URL;

  const [ nominated, setNominated ] = useState([]);
	const token = context.User.token;

  useEffect(() => {
		(async () => {
			const respOrder = await axios.get(serverURL+'/parts/nominated', {headers: {'x-access-token': token }});
			if (respOrder !== undefined) setNominated(respOrder.data);
		})();
	}, []);

	const handleDelete = async (row) => {
		const resp = await confirm('By removing this nominated part a notification will be sent to the user to add a new part to select a replacement. Press OK to continue.', { title: 'Remove nominated part?'});
		if (resp) {
			const result = await axios.put(serverURL+'/parts/nominated/delete', {
				id: row.id, 
				year: row.year, 
				part_id: row.part_id, 
				lug_id: row.lug_id, 
				user_id: row.user_id
			}, { headers: {'x-access-token': token }});
			if (result.status === 200) {
				addToast('Part removed!', {
					appearance: 'success',
					autoDismiss: true,
					autoDismissTimeout: 10000
				});
				let arr = [...nominated];
				let idx = nominated.findIndex(i => i.id === row.id);
				if (idx > -1) {
					arr.splice(idx, 1);
					setNominated(arr);
				}
			} else {
				addToast(result.message, {
					appearance: 'error', 
					autoDismiss: true,
					autoDismissTimeout: 15000
				});
			}
		}
	};

	const moveToVote = (e) => {
		e.preventDefault();
	};

	const moveToOrdering = async (e) => {
		e.preventDefault();
		const resp = await confirm('This action will move all \"checked\" parts nominated, to the order sheet. Press OK to move these parts to be \"available to order\".', { title: 'Move nominated parts'});
		if (resp) {
			try {
				const result = await axios.put(serverURL+'/parts/lug/order/nominated', nominated, { headers: {'x-access-token': token }});
				if (result.status === 200) {
					addToast('Parts moved!', {
						appearance: 'success',
						autoDismiss: true,
						autoDismissTimeout: 10000
					});
					let newNominated = nominated.reduce((newNominated, n) => {
						if (n.checked === false) newNominated.push(n);
						return newNominated;
					}, []);
					setNominated(newNominated);
				}
			} catch (err) {
				addToast('Error moving parts.', {
					appearance: 'error', 
					autoDismiss: true,
					autoDismissTimeout: 15000
				});
			}
		}
	}

	const handleChecked = async (part_id, chcked) => {
		const result = await axios.put(serverURL+'/parts/nominated/checked', {
				id: part_id, 
				checked: chcked
			}, { 
				headers: {'x-access-token': token }
			});
		if (result.status !== 200) {
			addToast(result.message || 'Error updating nomination', {
				appearance: 'error', 
				autoDismiss: true,
				autoDismissTimeout: 15000
			});
		} else {
			let arr = [...nominated];
			let idx = nominated.findIndex(i => i.part_id === part_id);
			if (idx > -1) {
				arr[idx]['checked'] = chcked;
				setNominated(arr);
			}
		}
	}

	const TableActions = ({ row }) => {
		return (
			<>
				<Button onClick={() => { handleDelete(row)}} variant="link" size="sm"><FontAwesomeIcon icon={faTrashAlt} color="black"/></Button>
			</>);
	};

  const columns = [
		{
			name: "Image",
			cell: row => <PartImage part_id={row.part.id} width={40} />
		},
		{
			name: "Part Id",
			selector: "part_id",
			sortable: true
		},
		{
			name: "User",
			selector: "user.username",
			sortable: true
		},
		{
			name: "Created",
			selector: "createdAt",
			cell: row => <Moment format='DD-MM-YYYY hh:mm' >{row.createdAt}</Moment>,
			sortable: true
		},
		{
			name: "Price (EUR)",
			selector: "part.price",
		},
		{
			name: "Checked",
			selector: "checked",
			cell: row => <CheckInput key={"quantity-" + row.id} index={row.part_id} value={row.checked} handleChecked={handleChecked} />
		},
		{
			name: "Actions",
			cell: row => <TableActions row={row} />,
			width: "100px"
		}
  ];

  return (
    <>
    <Card className="striped-tabled-with-hover">
      <Card.Header>
				Nominations {year} [{nominated.length}]
				<Dropdown className="ml-auto" variant="inverse">
					<Dropdown.Toggle id="dropdown-basic" className="btn-clear">&nbsp;</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item as={Link} to="/parts" onClick={moveToVote}>Move to Vote</Dropdown.Item>
						<Dropdown.Item as={Link} to="/ordering" onClick={moveToOrdering}>Move to Order</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</Card.Header>
      <Card.Body className="table-full-width table-responsive px-0">
      <DataTable 
				columns={columns} 
				data={nominated} 
				striped
				noHeader
			/>
      </Card.Body>
    </Card>
    </>
  );
}

export default NominatedList;
import React, { useState, useEffect, useContext } from "react";
import { LugBulkContext } from "../Context/LugBulkContext";

import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faUserTimes, faCogs, faTruck, faClipboardList } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';

function AdminBar({admin}) {
  const { context } = useContext(LugBulkContext);
  const serverURL = process.env.REACT_APP_SERVER_URL;

  const [ users, setUsers ] = useState([]);
  const [ nonPickers, setNonPickers ] = useState([]);
  const [ nonVoters, setNonVoters ] = useState([]);
  const [ orderCount, setOrderCount ] = useState(0);

  const token = context.User.token;
	const lug = context.Lug;

  const [ showNonVotersModal, setShowNonVotersModal ] = useState(false);
  const handleCloseNonVoters = () => setShowNonVotersModal(false);
  const handleShowNonVoters = () => setShowNonVotersModal(true);

  const [ showNonPickersModal, setShowNonPickersModal ] = useState(false);
  const handleCloseNonPickers = () => setShowNonPickersModal(false);
  const handleShowNonPickers = () => setShowNonPickersModal(true);

  useEffect(() => {
		(async () => {
			const userresp = await axios.get(serverURL+'/users', {headers: {'x-access-token': token }});
			if (userresp !== undefined) setUsers(userresp.data);
			const noteresp = await axios.get(serverURL+'/users/notVoted', {headers: {'x-access-token': token }});
			if (noteresp !== undefined) setNonVoters(noteresp.data);
      const nomresp = await axios.get(serverURL+'/users/notPicked', {headers: {'x-access-token': token }});
			if (nomresp !== undefined) addTotal(nomresp.data);
      const orderresp = await axios.get(serverURL+'/orders/count', {headers: {'x-access-token': token }});
			if (orderresp !== undefined) setOrderCount(orderresp.data.count);
		})();
	}, [admin]);

  const addTotal = (data) => {
    data.push({
      username: 'Total',
      limit: getTotals(data, 'limit'),
      user_nominated: getTotals(data, 'user_nominated'),
    });
    setNonPickers(data);
  };

  const getTotals = (data, key) => {
    let total = 0;
    data.forEach(item => {
      total += item[key];
    });
    return total;
  };

  const ModalNonVoters = ({data}) => {
		return (
			<>
			<Modal 
				show={showNonVotersModal} 
				onHide={handleCloseNonVoters}
				backdrop="static"
        keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>Users not voted ({lug && lug.lug_year}) [{nonVoters && nonVoters.length}]</Modal.Title>
				</Modal.Header>
				<Modal.Body>
          {nonVoters.length > 0 &&
            <ul>
            {nonVoters.map((u, i) => {
              return (
                <li key={i}>{u.username}</li>
              )}
            )}
            </ul>}
				</Modal.Body>
			</Modal>
			</>
		);
	};

  const zeroSelectedStyle = [
    {
      when: row => row.user_nominated === 0 && row.limit !== 0,
      style: {
        backgroundColor: '#F9C4C4',
        color: '#9A0C0C',
      }
    }, {
      when: row => row.user_nominated > 0 && row.user_nominated < row.limit,
      style: {
        backgroundColor: '#F9EFC9',
        color: '#CFA817',
      }
    }, {
      when: row => row.username === 'Total',
      style: {
        fontWeight: 'bold'
      }
    }
  ]

  const ModalNonPickers = ({data}) => {
		return (
			<>
			<Modal 
				show={showNonPickersModal} 
				onHide={handleCloseNonPickers}
				backdrop="static"
        keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>User Nominations ({lug && lug.lug_year}) [{nonPickers && nonPickers.length}]</Modal.Title>
				</Modal.Header>
				<Modal.Body>
        <DataTable 
          columns={columns} 
          data={nonPickers} 
          striped={true}
          dense={true} 
          conditionalRowStyles={zeroSelectedStyle}/>
				</Modal.Body>
			</Modal>
			</>
		);
	};

  const columns = [
		{
			name: "UserName",
			selector: row => row.username,
			sortable: true
		},
		{
			name: "Limit",
			selector: row => row.limit,
      sortable: true,
      width: '100px',
		},
		{
			name: "Picked",
			selector: row => row.user_nominated,
      sortable: true,
      width: '100px',
		}
	];

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">LUG Members</p>
                      <Card.Title as="h4">{users && users.length}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <Link to="/lug/users" ><FontAwesomeIcon icon={faUsers} /> Edit Users</Link>
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-light-3 text-success"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Activity {lug && lug.lug_year}</p>
                      <Card.Title as="h4">{nonPickers && nonPickers.length}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <Link to="#" onClick={handleShowNonPickers}><FontAwesomeIcon icon={faUserTimes} /> View Nominate Count</Link>
                  <Link to="#" onClick={handleShowNonVoters}><FontAwesomeIcon icon={faUserTimes} /> View Non Voters</Link>
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-vector text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Orders</p>
                      <Card.Title as="h4">{orderCount.length}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <Link to="/lug/orders"><FontAwesomeIcon icon={faTruck} /> View Orders</Link>
                  <Link to="/lug/countsheet"><FontAwesomeIcon icon={faClipboardList} /> Count Sheet</Link>
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-favourite-28 text-primary"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Current Year</p>
                      <Card.Title as="h4">{lug && lug.lug_year}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <Link to="/lug/settings"><FontAwesomeIcon icon={faCogs} /> Update Settings</Link>
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
      <ModalNonVoters data={nonVoters} />
      <ModalNonPickers data={nonPickers} />
    </>
  );

}

export default AdminBar;
import React, { useState, useEffect, useContext } from "react";
import { LugBulkContext } from "../Context/LugBulkContext";


import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Nav, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import Moment from 'react-moment';
import { useToasts } from 'react-toast-notifications'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faCheck, faTimes, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { confirm } from 'react-bootstrap-confirmation';

function Users() {
	const { context } = useContext(LugBulkContext);
  const token = context.User.token;
  const lug = context.Lug;
	const serverURL = process.env.REACT_APP_SERVER_URL;
	
  const [ users, setUsers ] = useState([]);
	const { addToast } = useToasts();

	useEffect(() => {
		(async () => {
			const userresp = await axios.get(serverURL+'/users', {headers: {'x-access-token': token }});
			if (userresp !== undefined) setUsers(userresp.data);
		})();
	}, []);

	const UserStatus = ({ row }) => {
		if (row.active) {
			return (
				<span className="fa-layers fa-fw" role="button" onClick={() => { toggleStatus(row)}}>
					<FontAwesomeIcon icon={faSquare} color="green" />
					<FontAwesomeIcon icon={faCheck} inverse transform="shrink-6" />
				</span>
			)
		} else {
			return (
				<span className="fa-layers fa-fw" role="button" onClick={() => { toggleStatus(row)}}>
					<FontAwesomeIcon icon={faSquare} color="darkred" />
					<FontAwesomeIcon icon={faTimes} inverse transform="shrink-6" />
				</span>
			)
		}
	};

	const toggleStatus = async (r) => {
		const result = await axios.put(serverURL+'/users/status/' + r.id, {active: r.active}, { headers: {'x-access-token': token }});
		if (result.status === 200) {
			let _idx = users.findIndex(u => u.id === r.id);
			if (_idx > -1) users[_idx].active = !r.active;
			setUsers(users);
		}
	};

	const handleDelete = async (_id) => {
		const resp = await confirm('Are you sure you want to delete this user?');
		if (resp) {
			const result = await axios.put(serverURL+'/users/delete/' + _id, {}, { headers: {'x-access-token': token }});
			if (result.status === 200) {
				let _idx = users.findIndex(u => u.id === _id);
				if (_idx > -1) users.splice(_idx, 1);
				setUsers(users);
				addToast('User removed!', {
					appearance: 'success',
					autoDismiss: false,
				});
			} else {
				addToast(result.message, {
					appearance: 'error',
					autoDismiss: false,
				});
			}
		}
	};

	const UserActions = ({ row }) => {
		return (
			<>
				<EditLink id={row.id} text={row.username}></EditLink>
			</>);
	};

	const TableActions = ({ row }) => {
		return (
			<>
				<EditLink id={row.id}></EditLink>
				<Button onClick={() => { handleDelete(row.id)}} variant="link" size="sm"><FontAwesomeIcon icon={faTrashAlt} color="black"/></Button>
			</>);
	};

	const EditLink = ({id, text = ''}) => {
		let html = (!text || text === '')? <FontAwesomeIcon icon={faPencilAlt} color="black"/>: text;
		return (<Link to={'/lug/users/edit/' + id} >{html}</Link>)
	};

	const columns = [
		{
			name: "UserName",
			selector: "username",
			cell: row => <UserActions row={row} />,
			sortable: true
		},
		{
			name: "Nominate",
			selector: "nominate_limit",
			width: "80px"
		},
		{
			name: "Vote",
			selector: "vote_limit",
			width: "80px"
		},
		{
			name: "Active",
			selector: "active",
			cell: row => <UserStatus row={row} />,
			width: "100px"
		},
		{
			name: "Last Login",
			selector: "last_login",
			cell: row => <Moment format='DD-MM-YYYY hh:mm' >{row.last_login}</Moment>,
			sortable: true
		},
		{
			name: "Actions",
			cell: row => <TableActions row={row} />,
			width: "100px"
		}
	];

  return (
  	<>
      <Container fluid>
      	<Row>
      		<Col xs md="12">
      			<Card className="striped-tabled-with-hover">
      				<Card.Header>Users in {lug.name} [{users.length}]
								<Nav variant="pills" className="ml-auto" defaultActiveKey="/user/new">
									<Nav.Item>
										<Nav.Link as={Link} to="/lug/users/add">New User</Nav.Link>
									</Nav.Item>
								</Nav>
							</Card.Header>
      				<Card.Body className="table-full-width table-responsive px-0">
							<DataTable 
									columns={columns} 
									data={users} 
									striped={true} 
									fixedHeaderScrollHeight="80vh" />
      				</Card.Body>
      			</Card>
      		</Col>
      	</Row>
      </Container>
      </>
  );
}

export default Users;
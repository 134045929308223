import React, { useContext, useEffect, useState } from "react";
import { LugBulkContext } from "../Context/LugBulkContext";

import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import routes from './Routes';

function Navigation() {
	const { context, logout } = useContext(LugBulkContext);
	const user = context.User;
	const [ admin, setAdmin ] = useState(false);

	useEffect(() => {
		if (user) {
			const arr = user.lug_adm.split(',').map(Number);
			if (arr.length > 0) {
				setAdmin(arr.includes(user.id));
			}
		}
  }, [user]);

	const SubMenuLink = ({link}) => {
		return (<>
			{((link.admin === false || link.admin === admin) && link.display === true)? 
				<Nav.Link className="nav-link" as={Link} to={link.path} >
				{link.icon && <FontAwesomeIcon icon={link.icon}/>} {link.name}
			</Nav.Link> : null }</>
		);
	}

	return (
		<>
			<Nav>
				{routes.map((r, i) => {
					//return ( (r.children && r.children.length > 0)? <dropDownMenu children={r.children} /> : <subMenuLink link={r} /> )
					return (<SubMenuLink link={r} key={i}/>)
				})}
				<Nav.Link className="nav-link" as={Link} to="/" onClick={logout}>
					<FontAwesomeIcon icon={faSignOutAlt}/> Logout
				</Nav.Link>
			</Nav>
		</>
	);
}

export default Navigation;
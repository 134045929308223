import React from "react";
import { useLocation } from "react-router-dom";
import { Navbar, Container, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import routes from "./Routes";
import NominatedPartUser from "../Parts/NominatedPartUser";
import UserNotifications from "../Users/UserNotifications";

function Header() {
	const location = useLocation();
	const mobileSidebarToggle = (e) => {
		e.preventDefault();
		document.documentElement.classList.toggle("nav-open");
		var node = document.createElement("div");
		node.id = "bodyClick";
		node.onclick = function () {
			this.parentElement.removeChild(this);
			document.documentElement.classList.toggle("nav-open");
		};
		document.body.appendChild(node);
	};

	const getLinkText = () => {
		for (let i = 0; i < routes.length; i++) {
			if (location && location.pathname){
				let path = (location.pathname.length > 1 && location.pathname.endsWith('/'))? location.pathname.slice(0, -1): location.pathname;
				if (path === routes[i].path) return <><FontAwesomeIcon icon={routes[i].icon}/> {routes[i].name}</>;
			}
		}
		return '';
	};

	return (
		<>
		<Navbar bg="light" expand="lg">
			<Container fluid>
				<div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
					<Button variant="dark" className="d-lg-none btn-fill p-2" onClick={mobileSidebarToggle}>
						<span className="burger-lines"></span>
						<span className="burger-lines"></span>
						<span className="burger-lines"></span>
					</Button>
					{getLinkText()}
				</div>
				<div className="d-flex ml-2 ml-lg-0 notification">
					<UserNotifications />
					<NominatedPartUser />			
				</div>
			</Container>
		</Navbar>
		</>
	);

}

export default Header;
import React, { useState, useEffect, useContext } from "react";
import { LugBulkContext } from "../Context/LugBulkContext";

import { useParams, Link } from 'react-router-dom';
import { useHistory } from "react-router"
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications'
import axios from 'axios';

function UserEdit() {
  const { context } = useContext(LugBulkContext);
  const token = context.User.token;
  const lug = context.Lug;
  const serverURL = process.env.REACT_APP_SERVER_URL;

  const [ user, setUser ] = useState({
    username: "",
    email: "",
    active: false,
    lug_id: lug.id
  });
  const { id } = useParams();
  const { addToast } = useToasts();
  const history = useHistory();
  const isAddMode = !id;

	useEffect(() => {
		(async () => {
      if (!isAddMode) {
        const userresp = await axios.get(serverURL+'/users/' + id, {headers: {'x-access-token': token }});
			if (userresp && userresp.status === 200) setUser(userresp.data);
      }
		})();
	}, []);

  const handleSave = async (e) => {
    e.preventDefault();
    let _uri = (isAddMode)? 'users/create': 'users/update';
    const result = await axios.put(serverURL+'/' + _uri, user, { headers: {'x-access-token': token }});
    if (result.status === 200) {
      addToast('User saved!', {
        appearance: 'success',
        autoDismiss: false,
      });
      history.push('/lug/users');
    } else {
      addToast(result.message, {
        appearance: 'error',
        autoDismiss: false,
      });
    }
  }

  const handleInputChange = async (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setUser({...user, 
      [name]: value
    });
  }

  return (
  	<>
      <Container fluid>
      	<Row>
      		<Col xs={12} md={8}>
      			<Card className="striped-tabled-with-hover">
      				<Card.Header>{isAddMode ? 'Add User' : 'Edit User'}</Card.Header>
      				<Card.Body>
							<Form>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label>User Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter a username" name="username" value={user.username} onChange={handleInputChange}/>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" name="email" value={user.email} onChange={handleInputChange}/>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3">
                    <Form.Check type="switch" label="Active" name="active" checked={user.active} onChange={handleInputChange} id="activeUser"/>
                  </Form.Group>
                </Row>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column xs={12} >Nomination Limit</Form.Label>
                  <Col xs={4} sm={2}>
                    <Form.Control type="number" name="nominate_limit" value={user.nominate_limit == null ? '': user.nominate_limit} onChange={handleInputChange}/>  
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Col md={12}>
                    <Form.Text className="text-muted">
                      Enter a nominate limit for this user. (This limit overrides the Lug limit)
                    </Form.Text>
                  </Col>
                </Form.Group>
              
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column xs={12} >Vote Limit</Form.Label>
                  <Col xs={4} sm={2}>
                    <Form.Control type="number" name="vote_limit" value={user.vote_limit == null ? '': user.vote_limit} onChange={handleInputChange}/>  
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Col md={12}>
                    <Form.Text className="text-muted">
                      Enter a vote limit for this user. (This limit overrides the Lug limit)
                    </Form.Text>
                  </Col>
                </Form.Group>
                
                <Button variant="primary" onClick={handleSave}>Submit</Button>
                <Link to={isAddMode ? '.' : '..'} className="btn btn-link">Cancel</Link>
              </Form>
      				</Card.Body>
      			</Card>
      		</Col>
      	</Row>
      </Container>
      </>
  );
}

export default UserEdit;
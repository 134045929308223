import React from 'react';
import LegoLoading from '../../assets/img/lego-loading.gif';
function CustomLoader() {

  return (
    <div style={{ padding: '24px' }}>
      <img src={LegoLoading} width={100} alt="" />
    </div>
  );
}

export default CustomLoader;
import React, { useState, useEffect } from "react";

function OrderTotalBox({orderTotal}) {
  const [ shipValue, setShipValue ] = useState(0);
  const [ taxValue, setTaxValue ] = useState(0);
  const [ grandTotal, setGrandTotal ] = useState(0);

  const taxrate  = process.env.REACT_APP_DEFAULT_TAXRATE;
  const shipping = process.env.REACT_APP_EST_SHIP_PERCENT;

  useEffect(() => {
    setShipValue(parseFloat(orderTotal * parseFloat(shipping)));
  }, [orderTotal]);

  useEffect(() => {
    setTaxValue(parseFloat(orderTotal + shipValue) * parseFloat(taxrate));
  }, [shipValue]);

  useEffect(() => {
    setGrandTotal(parseFloat(orderTotal + shipValue + taxValue)); 
  }, [taxValue]);

  return (
    <>
      <div className="orderTotal">
        <div>
          <span>Sub Total:</span><span>{orderTotal.toFixed(2)}</span>
        </div>
        <div>
          <span>Shipping:</span><span>{shipValue.toFixed(2)}</span>
        </div>
        <div>
          <span>Tax:</span><span>{taxValue.toFixed(2)}</span>
        </div>
        <div>
          <span>Grand Total:</span><span>{grandTotal.toFixed(2)}</span>
        </div>
      </div>
    </>
  );

}

export default OrderTotalBox;
import React, { useState, useEffect, useContext } from "react";
import { LugBulkContext } from "../Context/LugBulkContext";

import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications'
import axios from 'axios';
import ChangePassword from './UserChangePassword';
import UserOrderSummary from './UserOrderSummary';

function Profile() {
  const { context } = useContext(LugBulkContext);
  const token = context.User.token;
  const lug = context.Lug;
  const serverURL = process.env.REACT_APP_SERVER_URL;

  const [ user, setUser ] = useState({});
  
  const { addToast } = useToasts();

	useEffect(() => {
		(async () => {
      if (context.User) setUser(context.User);
		})();
	}, []);

  const handleSave = async (e) => {
    e.preventDefault();
    const result = await axios.put(serverURL+'/users/update', user, { headers: {'x-access-token': token }});
    if (result.status === 200) {
      addToast('User saved!', {
        appearance: 'success',
        autoDismiss: false,
      });
    } else {
      addToast(result.message, {
        appearance: 'error',
        autoDismiss: false,
      });
    }
  }

  const handleInputChange = async (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setUser({...user, 
      [name]: value
    });
  }

  return (
  	<>
      <Container fluid>
        <Row>
      		<Col xs={12} md={6}>
      			<Card className="striped-tabled-with-hover">
      				<Card.Header>My Orders</Card.Header>
      				<Card.Body>
                <UserOrderSummary></UserOrderSummary>
      				</Card.Body>
      			</Card>
      		</Col>
      		<Col xs={12} md={6}>
      			<Card className="striped-tabled-with-hover">
      				<Card.Header>My Profile</Card.Header>
      				<Card.Body>
							<Form>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label>User Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter a username" name="username" value={user.username} onChange={handleInputChange}/>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" name="email" value={user.email} onChange={handleInputChange}/>
                  </Form.Group>
                </Row>
                <Button variant="primary" onClick={handleSave}>Submit</Button>
              </Form>
      				</Card.Body>
      			</Card>
            <Card className="striped-tabled-with-hover">
      				<Card.Header>Update Password</Card.Header>
      				<Card.Body>
                <ChangePassword></ChangePassword>
              </Card.Body>
            </Card>
      		</Col>
      	</Row>
      </Container>
      </>
  );
}

export default Profile;
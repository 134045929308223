import React, { useState, useEffect, useContext, useMemo } from "react";
import { LugBulkContext } from "../Context/LugBulkContext";

import { useParams } from 'react-router-dom';
import axios from 'axios';
import UserOrderDetail from "../Users/UserOrderDetail";

function OrderListUser({data}) {
	const { context } = useContext(LugBulkContext);
	const token = context.User.token;
	const { id } = useParams();
	const serverURL = process.env.REACT_APP_SERVER_URL;

  const [ userOrder, setUserOrder ] = useState([]);
	const [ user, setUser ] = useState('');
	const [ pending, setPending ] = useState(true);

  useEffect(() => {
		setPending(true);
		(async () => {
			await axios.get(serverURL+'/orders/user/' + id, { headers: {'x-access-token': token }}).then(resp => {
				setUserOrder(resp.data);	
				setUser(resp.data[0].user.username);
			});
		})();
	}, []);

	useEffect(() => {
		if (user && user !== '') setPending(false);
	},[user]);

  return (
    <>
		<UserOrderDetail data={userOrder} readyState={pending} viewingUser={user}/>
    </>
  );
}

export default OrderListUser;
import React from "react";
import ReactDOM from 'react-dom';
import { LugBulkContext } from "../Context/LugBulkContext";

import { PDFViewer, Document, Page, View, Image, Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    flexDirection: "column",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

const PartImage = (part_id) => {
  return <Image src={`${process.env.PUBLIC_URL}/assets/img/parts/${part_id}.jpg`} style={styles.image} />
}

export default ({data, columns, styles}) => (
  <PDFViewer>
    <Document
      author="LUGBulk Ordering"
      title="Count Sheet"
    >
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.tableContainer}>
          <View fixed style={styles.tableHeader}>
          {columns.map((item, i) => (
            <View style={styles.row} key={`h`+i.toString()}>
              <PartImage part_id={item.selector}/>
              <Text style={styles.description}>{item.part_name}</Text>
            </View>
          ))}
          </View>
          <View style={styles.tableRowContainer}>
          {data.map((item, i) => (
            <View style={styles.row} key={`r`+i.toString()}>
              <Text style={styles.description}>{item.desc}</Text>
              <Text style={styles.xyz}>{item.xyz}</Text>
            </View>
          ))}
          </View>
        </View>
      </Page>
    </Document>
  </PDFViewer>
);

ReactDOM.render(<PDFViewer />, document.getElementById('root'));
import React, { useState, useContext } from "react";
import { LugBulkContext } from "../Context/LugBulkContext";

import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from "react-router";
import axios from 'axios';

function UserChangePassword() {
  const { context } = useContext(LugBulkContext);
  const token = context.User.token;
  const currentuser = context.User;
  const serverURL = process.env.REACT_APP_SERVER_URL;
  
  const [oldpassword, setOldPassword] = useState();
  const [newpassword, setNewPassword] = useState();
  const [checkpassword, setCheckPassword] = useState();

  const { addToast } = useToasts();
  const history = useHistory();
  
  const handleSave = async (e) => {
    e.preventDefault();
    const credentials = {
      oldpassword,
      newpassword,
      checkpassword
    };
    const result = await axios.put(serverURL+'/users/change/' + currentuser.id, {credentials}, { headers: {'x-access-token': token }});
    if (result.data.auth) {
      addToast('Password Updated!', {
        appearance: 'success', 
        autoDismiss: true,
        autoDismissTimeout: 10000
      });
      history.push('/');
    } else {
      addToast(result.data.message, {
        appearance: 'error', 
        autoDismiss: true,
        autoDismissTimeout: 10000
      });
    }
  }

  return (
  	<>			
      <Form>
        <Row className="mb-3">
          <Form.Group as={Col} className="mb-3">
            <Form.Label>Old Password</Form.Label>
            <Form.Control type="password" placeholder="Enter your old password" name="oldpassword" value={oldpassword} onChange={e => setOldPassword(e.target.value)}/>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} className="mb-3">
            <Form.Label>New Password</Form.Label>
            <Form.Control type="password" placeholder="Enter a new password" name="newpassword" value={newpassword} onChange={e => setNewPassword(e.target.value)}/>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} className="mb-3">
            <Form.Label>Confirm New Password</Form.Label>
            <Form.Control type="password" placeholder="Confirm your new password" name="checkpassword" value={checkpassword} onChange={e => setCheckPassword(e.target.value)}/>
          </Form.Group>
        </Row>
        
        <Button variant="primary" onClick={handleSave}>Change Password</Button>
      </Form>
      </>
  );
}

export default UserChangePassword;
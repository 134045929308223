import { faHome, faPoll, faCubes, faFolder, faUsersCog, faTruck, faCogs, faUsers, faUserPlus, faUserEdit, faUser, faClipboardList, faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";

const dashboardRoutes = [
  {
    path: "/",
    name: "Dashboard",
    icon: faHome,
    display: true,
    admin: false
  },{
    path: "/parts",
    name: "Parts",
    icon: faCubes,
    display: true,
    admin: false
  },{
    path: "/parts/new",
    name: "New Parts",
    icon: faCubes,
    display: false,
    admin: false
  },{
    path: "/voting",
    name: "Voting",
    icon: faPoll,
    display: true,
    admin: false
  },{
    path: "/ordering",
    name: "Ordering",
    icon: faFolder,
    display: true,
    admin: false
  },{
    path: "/my-profile",
    name: "My Profile",
    icon: faUser,
    admin: false,
    display: true,
  },{
    path: "/lug",
    name: "My Lug",
    icon: faUsersCog,
    admin: true,
    display: true,
  },{
    path: "/lug/settings",
    name: "Lug Settings",
    icon: faCogs,
    admin: true,
    display: false,
  },{
    path: "/lug/users",
    name: "Lug Users",
    icon: faUsers,
    admin: true,
    display: false,
  },{
    path: "/lug/users/add",
    name: "Add User",
    icon: faUserPlus,
    admin: true,
    display: false,
  },{
    path: "/lug/users/edit/:id",
    name: "Edit User",
    icon: faUserEdit,
    admin: true,
    display: false,
  },{
    path: "/lug/orders",
    name: "Lug Part Order",
    icon: faTruck,
    admin: true,
    display: false,
  },{
    path: "/lug/invoicing",
    name: "Lug Invoicing",
    icon: faFileInvoiceDollar,
    admin: true,
    display: false,
  },{
    path: "/lug/countsheet",
    name: "Order Countsheet",
    icon: faClipboardList,
    admin: true,
    display: false,
  }
];

export default dashboardRoutes;
import React, { useState, useEffect, useContext } from "react";
import { LugBulkContext } from "../Context/LugBulkContext";

import { Link } from 'react-router-dom';
import Navigation from './Navigation';
import bgImage from '../../assets/img/bg-legoblack.jpg';

function Sidebar() {
	const { context } = useContext(LugBulkContext);
	const lug = context.Lug;

	return (
		<div className="sidebar" data-image={bgImage} >
			<div className="sidebar-background"/>
			<div className="sidebar-wrapper" style={{backgroundImage: `url(${bgImage})`}}>
				<div className="logo d-flex align-items-center justify-content-start">
					<Link to="/" className="simple-text logo-mini mx-1">
						<div className="logo-img">
							<img src={lug.logo} alt="" />
						</div>
					</Link>
					 LUGBulk
				</div>
				<Navigation />
			</div>
		</div>
	);

}

export default Sidebar;
import React, { useMemo, useState, useEffect, useContext } from "react";
import { LugBulkContext } from "../Context/LugBulkContext";

import DataTable from 'react-data-table-component';
import { useToasts } from 'react-toast-notifications'
import { Container, Row, Col, Card, Alert, Button, Badge } from 'react-bootstrap';
import axios from 'axios';
import PartImage from './PartImage';
import CustomLoader from '../Widgets/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';
import styled, { css } from 'styled-components';

import SelectSearch, { fuzzySearch } from 'react-select-search';
import "../../assets/css/select-style.css";

const TextField = styled.input`
	display: flex;
	flex: 1 1 auto;
	font-family: 'Noto Sans', sans-serif;
	font-size: 14px;
  height: 32px;
  border-radius: 3px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  &:hover {
    cursor: pointer;
  }
`;

const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  height: 32px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear, label }) => (
	<>
	<div className="filter-item">
		<TextField
			id="search"
			type="text"
			placeholder={label}
			aria-label="Search Input"
			value={filterText}
			onChange={onFilter}
		/>
		<ClearButton type="button" onClick={onClear} variant="dark">X</ClearButton>
	</div>
	</>);

const FilterSelectComponent = ({ data, onFilter, onClear, label, value }) => (
	<>
	<div className="filter-item">
		<SelectSearch 
			options={data} 
			search 
			filterOptions={fuzzySearch} 
			onChange={onFilter} 
			value={value} 
			emptyMessage="Not found" 
			placeholder={label} 
		/>
		<ClearButton type="button" onClick={onClear} variant="dark">X</ClearButton>
	</div>
	</>);



function Parts() {
	const { context, setNominations } = useContext(LugBulkContext);
	const user = context.User;
	const token = context.User.token;
	const lug = context.Lug;
	const serverURL = process.env.REACT_APP_SERVER_URL;

  const [ data, setData ] = useState([]);
	const [ colors, setColors ] = useState([]);
	const [ blcolors, setBLColors ] = useState([]);

  const { addToast } = useToasts();
	const [ pending, setPending ] = useState(true);
  const [ expired, setExpired ] = useState(false);

	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [filterText, setFilterText] = useState('');
	const [filterColor, setFilterColor] = useState('');
	const [filterBLColor, setFilterBLColor] = useState('');
	const [filterDesign, setFilterDesign] = useState('');
	const [filterPart, setFilterPart] = useState('');

  useEffect(() => {
		(async () => {
			await axios.get(serverURL+'/parts', { headers: {'x-access-token': token }}).then(resp => {
				setData(resp.data);	
        isExpired();
			}).then(something => {
				axios.get(serverURL+'/colors', { headers: {'x-access-token': token }}).then(resp => {
					let _arr = [];
					resp.data.map(c => _arr.push({value: c.id, name: c.title}));
					setColors(_arr);	
				});
			}).then(something => {
				axios.get(serverURL+'/colors/bl', { headers: {'x-access-token': token }}).then(resp => {
					let _arr = [];
					resp.data.map(c => _arr.push({value: c.id, name: c.title}));	
					setBLColors(_arr);	
				});
			}).then(something => {
				setPending(false);
			});
		})();
  }, []);

	const ImageCell = (row) => {
		return (<>
			{row.row.year_added === lug.lug_year && <Badge className="new-product" bg="success" >NEW</Badge>}
			<PartImage part_id={row.row.id} width={100} />
		</>);
	}

	const columns = useMemo(
		() => [
				{
					name: "Image", 
					cell: row => <ImageCell row={row} />
				}, {
					name: "Part Id", 
					selector: "id", 
					sortable: true,
					width: "100px",
				}, {
					name: "Design Id", 
					selector: "design_id", 
					sortable: true,
				}, {
					name: "Title", 
					selector: "title", 
					sortable: true,
					grow: 2,
				}, {
					name: "Category", 
					selector: "category", 
					sortable: true,
				}, {
					name: "Lego Colour", 
					selector: "color.title", 
					sortable: true,
				}, {
					name: "BL Colour", 
					selector: "blcolor.title", 
					sortable: true,
				}, {
					name: "Price (EUR)", 
					selector: "price",
					width: "120px",
					sortable: true,
					right: true,
				}, {
					name: "Price (AUD)", 
					width: "120px",
					right: true,
					cell: row => estimateFX(row.price)
				}, {
					name: "Nominate",
					width: "100px",
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
					cell: row => <FontAwesomeIcon icon={faClipboardCheck} onClick={handleSelect(row)} size="lg" role="button" />
				}
		], [setNominations, lug, expired]
	);

  const isExpired = () => {
		if (!lug.nominate_end) return false;
		let _now = new Date();
		let _chkdate = new Date(lug.nominate_end); 
		setExpired((+_chkdate < +_now)? 'disabled' : false);
	};

	const estimateFX = (amount) => {
		return (amount * 1.65225).toFixed(4);
	}

  const handleSelect = part => async (e) => {
    e.preventDefault();
    if (expired === 'disabled') {
      addToast('Nominations have closed!', {
        appearance: 'error', 
        autoDismiss: true,
        autoDismissTimeout: 15000
      });
      return;
    }
    let arr = [...context.Nominations];
    let idx = arr.findIndex(v => v.part_id === part.id);
    let _limit = lug.part_limit;
    if (user && (user.nominate_limit === 0 || user.nominate_limit > 0)) _limit = user.nominate_limit;

    switch (true) {
      case (arr.length < _limit && idx === -1):
        arr.push({ part_id: part.id, title: part.title, year: part.year_added});
        setNominations(arr);
      break;
      case (arr.length <= _limit && idx !== -1):
        addToast('You have already added part ' + part.title, {
          appearance: 'warning', 
          autoDismiss: true,
          autoDismissTimeout: 10000
        });
      break;
      default:
        addToast('You have reached your limit of (' + _limit + ') nominations', {
          appearance: 'error', 
          autoDismiss: true,
          autoDismissTimeout: 15000
        });
    }
  };

	const filteredItems = data.filter((part) =>
		(part.id && part.id.toString().includes(filterPart.toString()))
		&& (part.design_id && part.design_id.toString().includes(filterDesign.toString()))
		&& (part.color_id && part.color_id.toString().includes(filterColor.toString()))
		&& (part.blcolor_id && part.blcolor_id.toString().includes(filterBLColor.toString()))
		&& (part.title && part.title.toLowerCase().includes(filterText.toLowerCase()))
	);

	const subHeaderComponentMemo = React.useMemo(() => {
		const handlePartClear = () => {
			if (filterPart) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterPart('');
			}
		};
		const handleDesignClear = () => {
			if (filterDesign) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterDesign('');
			}
		};
		const handleTextClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};
		const handleColorClear = () => {
			if (filterColor) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterColor('');
			}
		};
		const handleBLColorClear = () => {
			if (filterBLColor) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterBLColor('');
			}
		};
	
		return (<>
		<div className="filter-row">
			<FilterComponent 
				onFilter={e => setFilterPart(e.target.value)}     
				onClear={handlePartClear}    
				filterText={filterPart}   
				label="Filter by PartId"/>
			<FilterComponent 
				onFilter={e => setFilterDesign(e.target.value)}   
				onClear={handleDesignClear}  
				filterText={filterDesign} 
				label="Filter by DesignId"/>
			<FilterComponent 
				onFilter={e => setFilterText(e.target.value)}     
				onClear={handleTextClear}    
				filterText={filterText}   
				label="Filter by Title"/>
			<FilterSelectComponent 
				data={colors} 
				onFilter={setFilterColor}     
				onClear={handleColorClear}   
				value={filterColor}       
				label="Filter by LEGO Colour" />
			<FilterSelectComponent
				data={blcolors} 
				onFilter={setFilterBLColor} 
				onClear={handleBLColorClear} 
				value={filterBLColor}     
				label="Filter by BrickLink Colour" />
		</div>
		</>);
	}, [filterText, filterColor, filterBLColor, filterDesign, filterPart, colors, blcolors, resetPaginationToggle]);

  return (
	<>
    <Container fluid>
    	<Row>
    		<Col md="12">
          {expired === 'disabled' && <Alert key={10} variant={'danger'}>Nominations have now closed.</Alert> }
          {lug.nominate_end && !expired && <Alert key={10} variant={'warning'}>Nominations close on {<Moment format='Do MMMM YYYY' >{lug.nominate_end}</Moment>}.</Alert> }
    			<Card className="striped-tabled-with-hover">
    				<Card.Header>Parts List</Card.Header>
    				<Card.Body className="table-full-width table-responsive px-0">
							<DataTable 
								columns={columns} 
								data={filteredItems} 
								progressPending={pending}
								progressComponent={<CustomLoader />}
								pagination
								paginationResetDefaultPage={resetPaginationToggle}
								paginationRowsPerPageOptions={[10,25,50,100,300]}
                striped
								subHeader
								subHeaderComponent={subHeaderComponentMemo}
								noHeader
							/>
    				</Card.Body>
    			</Card>
    		</Col>
    	</Row>
    </Container>
    </>
  );
}

export default Parts;
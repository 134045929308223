import React, { useState, useEffect, useContext, useMemo } from "react";
import { LugBulkContext } from "../Context/LugBulkContext";

import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import CustomLoader from '../Widgets/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';


function Ordering() {
  const { context } = useContext(LugBulkContext);
	const user = context.User;
	const token = context.User.token;
	const lug = context.Lug;
  
  const [ orders, setOrders ] = useState([]);
  const [ pending, setPending ] = useState(true);
  const serverURL = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
		(async () => {
      await axios.get(serverURL+'/orders/summary', { headers: {'x-access-token': token }}).then(resp => {
				setOrders(resp.data);	
			}).then(something => {
				setPending(false);
			});
		})();
  }, []);
  
  const TableActions = ({ row }) => {
		return (
			<>
				<EditLink id={row.user_id}></EditLink>
				{/* <Button onClick={() => { handleDelete(row.id)}} variant="link" size="sm"><FontAwesomeIcon icon={faTrashAlt} color="black"/></Button> */}
			</>);
	};

  const EditLink = ({id, text = ''}) => {
		let html = (!text || text === '')? <FontAwesomeIcon icon={faEye} color="black"/>: text;
		return (<Link to={'/orders/user/' + id} >{html}</Link>)
	};

  const columns = React.useMemo(
    () => [
        {
          name: "Username",
          selector: "username",
        },
        {
          name: "Parts Ordered",
          selector: "total_parts",
          width: '120px',
        },
        {
          name: "Quantity",
          selector: "total_qty",
          width: '130px',
        },
        {
          name: "Value",
          selector: "total_value",
          width: '150px',
        },
        {
          name: "Actions",
          cell: row => <TableActions row={row} />,
          width: "80px"
        }
      ],
      [],
  );

  return (
    <>
      <Card className="striped-tabled-with-hover">
        <Card.Header>
          Orders {lug.lug_year} [{orders.length}]
        </Card.Header>
        <Card.Body className="table-full-width table-responsive px-0">
        <DataTable 
          columns={columns} 
          data={orders} 
          progressPending={pending}
          progressComponent={<CustomLoader />}
          striped
          noHeader
        />
        </Card.Body>
      </Card>
    </>
  );
}

export default Ordering;
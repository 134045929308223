import React, { useContext, useEffect } from 'react';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { LugBulkContext } from "../Context/LugBulkContext";

import Dashboard from './Dashboard';
import Parts from '../Parts/Parts_V2';
import PartsNew from '../Parts/New';
import Votes from '../Parts/Votes';
import Orders from '../Orders/Orders';
import Ordering from '../Orders/Ordering';
import OrderListUser from '../Orders/OrderListUser';
import Users from '../Users/Users';
import UserProfile from '../Users/Profile';
import UserEdit from '../Users/UserEdit';
import UserOrderDetail from '../Users/UserOrderDetail';
import Lug from '../Settings/Lug';
import LugSettings from '../Settings/LugSettings';
import CountSheet from '../Reports/CountSheet';


function Content() {

	const location = useLocation();
	const history = useHistory();
	const { context } = useContext(LugBulkContext);

	useEffect(() => {
		if (!context.Lug && !context.Lug.id) history.push('/');
	}, []);

	return (
		<>
		<Switch>
			<Route exact path="/parts">
				<Parts />
			</Route>
			<Route exact path="/parts/new">
				<PartsNew />
			</Route>
			<Route exact path="/ordering">
				<Ordering />
			</Route>
			<Route exact path="/voting">
				<Votes />
			</Route>
			<Route exact path="/lug/users">
				<Users />
			</Route>
			<Route exact path="/my-profile">
				<UserProfile />
			</Route>
			<Route exact path="/lug/users/add">
				<UserEdit />
			</Route>
			<Route exact path="/lug/users/edit/:id">
				<UserEdit />
			</Route>
			<Route exact path="/lug/orders">
				<Orders />
			</Route>
			<Route exact path="/orders/user/:id">
				<OrderListUser />
			</Route>
			<Route exact path="/orders/user/year/:year">
				<UserOrderDetail />
			</Route>
			<Route exact path="/lug/countsheet">
				<CountSheet />
			</Route>
			<Route exact path="/lug">
				<Lug />
			</Route>
			<Route exact path="/lug/settings">
				<LugSettings />
			</Route>
			<Route exact path="/">
				<Dashboard />
			</Route>
		</Switch>
		</>
	);

}

export default Content;